import style from "./Sidebar.module.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import dendeNewLogo from "../../assets/dende-new-logo.png";
import menuIcon from "../../assets/new/menuIcon.svg";
import dende_logo from "../../assets/dende-logo.png";
import { GetUserInfoAPI } from "../../helpers/requests";
// import HomeIcon
import {
  HomeIcon,
  CheckCircleIcon,
  RectangleStackIcon,
  LightBulbIcon,
  MagnifyingGlassIcon,
  BookOpenIcon,
  BuildingLibraryIcon,
  DocumentIcon,
  Cog6ToothIcon,
  ArrowRightEndOnRectangleIcon,
  ArrowRightStartOnRectangleIcon,
} from "@heroicons/react/24/outline";

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();

  const sidebar_element = useRef(null);
  const backdrop_element = useRef(null);

  // hide sidebar
  // const hideSidebar = current_pathname === "/quiz" || current_pathname === "/topic-completed";
  const showTopBarDendeLogo =
    location.pathname === "/quiz" || location.pathname === "/topic-completed";
  const showMobileTobBarDendeLogo = location.pathname === "/home";

  const [plan_type, set_plan_type] = useState("");
  const [days_left_state, set_days_left_state] = useState(0);
  const [window_width, set_window_width] = useState(window.innerWidth);
  const [show_manage_page, set_show_manage_page] = useState("");
  const [current_pathname, set_current_pathname] = useState("");
  const [show_sidebar, set_show_sidebar] = useState(true);

  useEffect(() => {
    getUserInfo();
  }, []);

  // get user info
  async function getUserInfo() {
    try{
      let user_info_res = undefined;
      user_info_res = await GetUserInfoAPI();
      if(user_info_res !== undefined){
        if(user_info_res.data && user_info_res.data.length > 0){
          set_plan_type(user_info_res.data[0]["plan_type"]);
          updatePushmodeDate(
            user_info_res.data[0]["plan_end_date"],
            user_info_res.data[0]["payment_type"]
          );
          if (user_info_res.data[0]["payment_type"] === "checkout") {
            set_show_manage_page("false");
          } else {
            set_show_manage_page("true");
          }
        }
      }
    } catch(err){
      console.error("sidebar", err);
    }
  }

  function updatePushmodeDate(plan_end_date, payment_type) {
      const planEndDate = plan_end_date;
      const endDate = new Date(planEndDate);
      const now = new Date();
      const timeDifference = endDate - now;
      let daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      daysLeft = daysLeft + 1;
      set_days_left_state(daysLeft);
  }

  // Event listener on path change.
  useEffect(() => {
    if (
      (location.pathname === "/quiz" ||
        location.pathname === "/reread" ||
        location.pathname === "/topic-completed") &&
      window_width >= 600
    ) {
      set_show_sidebar(false);
    } else {
      set_show_sidebar(true);
      backdrop_element.current.style.display = "none";
    }
  }, [location.pathname]);

  // Event listener on viewport width change.
  useEffect(() => {
    const handleResize = () => {
      set_window_width(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  function openSidebar() {
    sidebar_element.current.style.display = "block";
    backdrop_element.current.style.display = "block";
  }
  function closeSidebar() {
    sidebar_element.current.style.display = "none";
    backdrop_element.current.style.display = "none";
  }
  function logout() {
    localStorage.clear();
    sessionStorage.clear();
    let deleteDB = indexedDB.deleteDatabase("dendeLocalDB");
    deleteDB.onerror = function(event) {
      console.error("Error deleting database.", event.target.errorCode);
    };
    deleteDB.onsuccess = function(event) {
    };
    navigate("/login");
  }
  function openLink(path) {
    navigate(path);
    if (window.innerWidth <= 1100) {
      closeSidebar();
    }
  }
  const showLogo = () => {
    if (window_width <= 1100) {
      if (
        location.pathname === "/home" ||
        location.pathname === "/topic-completed" ||
        location.pathname === "/manage" ||
        location.pathname === "/reread"
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        // location.pathname === "/quiz" ||
        location.pathname === "/topic-completed" ||
        location.pathname === "/manage"
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  function handleShowSidebar(data) {
    const mainElement = document.getElementById("rereadContainer");
    if (
      location.pathname === "/quiz" ||
      (location.pathname === "/reread" && window_width >= 600)
    ) {
      if (location.pathname === "/reread") {
        if (mainElement) {
          mainElement.style.marginLeft = "240px";
        }
      }

      if (window_width >= 600) {
        if (data) {
          set_show_sidebar(true);
        } else {
          set_show_sidebar(false);
          if (mainElement) {
            mainElement.style.marginLeft = "140px";
          }
        }
      }
    }
  }

  function hambrugerMenuFixed(){
    if(window.innerWidth < 1000 && (location.pathname === "/quiz" || location.pathname === "/concept")){
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <section
        style={{
          display:
            location.pathname === "/reread" && window.innerWidth > 1000
              ? "none"
              : "",
        }}
        id="topbar-container"
        className={style["topbar-container"]}
      >
        <div className={style["topbar-content"]}>
          <img
            className={hambrugerMenuFixed() ? `${style["menu-icon-fixed"]} ${style["menu-icon"]}` : style["menu-icon"]}
            style={location.pathname === "/quiz" && window_width < 1000 && plan_type === "free" ? {top: '50px'} : {top: '15px'}}
            onClick={() => {openSidebar();}}
            src={menuIcon}
            alt="menu icon"
          />
          <img
            onClick={() => {
              navigate("/home");
            }}
            style={showLogo() ? { display: "block" } : { display: "none" }}
            className={style["dende-logo"]}
            src={dendeNewLogo}
            alt="logo"
          />
        </div>
      </section>
      <article
        onClick={() => {
          closeSidebar();
        }}
        ref={backdrop_element}
        className={style["sidebar-backdrop"]}
      ></article>
      <div
        ref={sidebar_element}
        className={style["sidebar-container"]}
        onMouseOver={() => handleShowSidebar(true)}
        onMouseLeave={() => handleShowSidebar(false)}
        style={show_sidebar ? { width: "190px" } : { width: "90px" }}
        // style={show_sidebar
        //     ?
        //         {display: "block"}
        //     :
        //         {display: "none"}}
      >
        <div className={style["sidebar-content"]}>
          <div>
            <div
              style={show_sidebar ? { display: "block" } : { display: "none" }}
              className={style["logo-section"]}
            >
              <Link to="/home">
                <img src={dendeNewLogo} alt="dende logo" />
              </Link>
            </div>

            <div
              style={show_sidebar ? { display: "none" } : { display: "block" }}
              className={style["each-menu-section"]}
            >
              <Link to="/home">
                <img
                  src={dende_logo}
                  alt="dende logo"
                  style={{ width: "20px", height: "20px" }}
                />
              </Link>
            </div>
            <div className={style["link-section"]}>
              <div
                onClick={() => {
                  openLink("/home");
                }}
                className={style["each-menu-section"]}
              >
                <div className={style["menu-icon-container"]}>
                  <HomeIcon className={style["menu-icons"]} />
                </div>
                <div
                  style={
                    show_sidebar ? { display: "block" } : { display: "none" }
                  }
                >
                  <p
                    style={
                      location.pathname === "/home"
                        ? { color: "#F18F01" }
                        : { color: "rgb(85, 85, 85)" }
                    }
                    className={style["link-text"]}
                  >
                    Home
                  </p>
                </div>
              </div>
              <div
                onClick={() => {
                  openLink("/quiz");
                }}
                className={style["each-menu-section"]}
              >
                <div className={style["menu-icon-container"]}>
                  <LightBulbIcon className={style["menu-icons"]} />
                </div>
                <div
                  style={
                    show_sidebar ? { display: "block" } : { display: "none" }
                  }
                >
                  <p
                    className={style["link-text"]}
                    style={
                      location.pathname === "/quiz"
                        ? { color: "#F18F01" }
                        : { color: "rgb(85, 85, 85)" }
                    }
                  >
                    Quiz
                  </p>
                </div>
              </div>
              <div
                onClick={() => {
                  openLink("/concept");
                }}
                className={style["each-menu-section"]}
              >
                <div className={style["menu-icon-container"]}>
                  <CheckCircleIcon className={style["menu-icons"]} />
                </div>
                <div
                  style={
                    show_sidebar ? { display: "block" } : { display: "none" }
                  }
                >
                  <p
                    className={style["link-text"]}
                    style={
                      location.pathname === "/concept"
                        ? { color: "#F18F01" }
                        : { color: "rgb(85, 85, 85)" }
                    }
                  >
                    Questions
                  </p>
                </div>
              </div>
              <div
                onClick={() => {
                  openLink("/reread");
                }}
                className={style["each-menu-section"]}
              >
                <div className={style["menu-icon-container"]}>
                  <BookOpenIcon className={style["menu-icons"]} />
                </div>

                <div
                  style={
                    show_sidebar ? { display: "block" } : { display: "none" }
                  }
                >
                  <p
                    className={style["link-text"]}
                    style={
                      location.pathname === "/reread"
                        ? { color: "#F18F01" }
                        : { color: "rgb(85, 85, 85)" }
                    }
                  >
                    Reread
                  </p>
                </div>
              </div>
              <div
                onClick={() => {
                  openLink("/flashcard");
                }}
                className={style["each-menu-section"]}
              >
                <div className={style["menu-icon-container"]}>
                  <RectangleStackIcon className={style["menu-icons"]} />
                </div>

                <div
                  style={
                    show_sidebar ? { display: "block" } : { display: "none" }
                  }
                >
                  <p
                    className={style["link-text"]}
                    style={
                      location.pathname === "/flashcard"
                        ? { color: "#F18F01" }
                        : { color: "rgb(85, 85, 85)" }
                    }
                  >
                    Flashcards
                  </p>
                </div>
              </div>
              <div
                onClick={() => {
                  openLink("/library");
                }}
                className={style["each-menu-section"]}
              >
                <div className={style["menu-icon-container"]}>
                  <BuildingLibraryIcon className={style["menu-icons"]} />
                </div>

                <div
                  style={
                    show_sidebar ? { display: "block" } : { display: "none" }
                  }
                >
                  <p
                    className={style["link-text"]}
                    style={
                      location.pathname === "/library"
                        ? { color: "#F18F01" }
                        : { color: "rgb(85, 85, 85)" }
                    }
                  >
                    Library
                  </p>
                </div>
              </div>
              <div
                onClick={() => {
                  openLink("/uploadfile");
                }}
                className={style["each-menu-section"]}
              >
                <div className={style["menu-icon-container"]}>
                  <DocumentIcon className={style["menu-icons"]} />
                </div>
                <div
                  style={
                    show_sidebar ? { display: "" } : { display: "none" }
                  }
                >
                  <p
                    className={style["link-text"]}
                    style={
                      location.pathname === "/uploadfile"
                        ? { color: "#F18F01" }
                        : { color: "rgb(85, 85, 85)" }
                    }
                  >
                    Documents
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={style["bottom-section"]}>
            <div
              style={{
                marginBottom: show_sidebar ? "30px" : "74px",
                width: "130px",
                height: "73px"
              }}
              className={style["bottom-section-link"]}
            >
              {show_manage_page === "true" ? (
                <>
                  <div
                    onClick={() => {
                      openLink("/manage");
                    }}
                    className={`${style["each-menu-section-profile"]}`}
                  >
                    <div>
                      <Cog6ToothIcon className={style["menu-icons-profile"]} />
                    </div>

                    <div
                      style={
                        show_sidebar
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <p
                        className={style["link-text-profile"]}
                        style={
                          location.pathname === "/manage"
                            ? { color: "#F18F01" }
                            : { color: "rgb(85, 85, 85)" }
                        }
                      >
                        Plan
                      </p>
                    </div>
                  </div>
                </>
              ) : show_manage_page === "false" ? (
                <>
                  <div
                    onClick={() => {
                      openLink("/profile");
                    }}
                    className={style["each-menu-section-profile"]}
                  >
                    <div>
                      <Cog6ToothIcon className={style["menu-icons-profile"]} />
                    </div>
                    <div
                      style={
                        show_sidebar
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <p
                        className={style["link-text-profile"]}
                        style={
                          location.pathname === "/profile"
                            ? { color: "#F18F01" }
                            : { color: "#000" }
                        }
                      >
                        Plan
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div
                onClick={() => {
                  logout();
                  closeSidebar();
                }}
                className={style["each-menu-section-logout"]}
              >
                <div>
                  <ArrowRightStartOnRectangleIcon
                    className={style["menu-icons-logout"]}
                  />
                </div>
                <div
                  style={
                    show_sidebar ? { display: "block" } : { display: "none" }
                  }
                >
                  <p className={style["logout-btn-sidebar"]}>Log out{` >`}</p>
                </div>
              </div>
            </div>
            <div
              className={style["pushmode-button-container"]}
              style={{ display: show_sidebar ? "block" : "none",
                  width: '130px',
                  height: '44px'
            }}
            >
              {plan_type === "free" ||
              plan_type === "" ||
              plan_type === null ? (
                <>
                  <p
                    onClick={() => {
                      openLink("/pushmode");
                    }}
                    className={style["pushmode-cta"]}
                  >
                    Push mode
                  </p>
                </>
              ) : (
                <>
                  <aside>
                    <h6>Push mode active</h6>
                    <p>
                      {days_left_state === "" ? (
                        <>{days_left_state} days left</>
                      ) : days_left_state > 0 ? (
                        <>{days_left_state} days left</>
                      ) : (
                        <></>
                      )}
                    </p>
                  </aside>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
