import React, { useEffect, useRef, useState } from "react";
import LoaderSpinner from "../../Components/LoaderSpinner/LoaderSpinner";
import { getConceptQuestionAPI } from "../../helpers/requests";
import styles from "./ConceptQuestion.module.css"
import playIcon from "../../assets/new/play.svg"
import playIconWithCircle from "../../assets/new/playIconWithCircle.svg"
import { useNavigate } from "react-router-dom";
import {ReactComponent as LeftArrowIcon} from "../../assets/new/left-arrow.svg"


export default function ChapterQuestions(){

	const [showLoadingGif, setShowLoadingGif] = useState(false);
	const [apiResponseJson, setApiResponseJson] = useState({});
	const [window_width, set_window_width] = useState(window.innerWidth);
	const navigate = useNavigate();
	let questionStatusNameMapping = {
		"correct": "Correct",
		"wrong": "Wrong",
		"unanswered": "New",
		"reread": "Refresh"
	}

	useEffect(() => {
		setShowLoadingGif(true);
		const urlParams = new URLSearchParams(window.location.search);
		if (urlParams.get("topic_id") !== null && urlParams.get("topic_id") !== undefined) {
			console.log('----------------------')
			console.log(urlParams.get("topic_id"))
			console.log('----------------------')
			getConceptQuestions(urlParams.get("topic_id"), urlParams.get("resource_id"))
		}
		const handleResize = () => {
			set_window_width(window.innerWidth);
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	async function getConceptQuestions(topicId, resourceId){
		let apiResponse = await getConceptQuestionAPI({topicId: topicId, resourceId: resourceId});
		if(apiResponse && apiResponse.data.question_list.length > 0){
			setApiResponseJson(apiResponse.data);
			setShowLoadingGif(false);
		}
	}

	function showProgessBar({answered, wrong, unanswered, reread}){
		if(answered === 0 && unanswered === 0 && wrong === 0 && reread === 0){
            unanswered = 100;
        }
        return(
            <>
                <div className={styles["progress-bar"]}>
					{
						answered > 0
						?
							<div 
								style={{width:`${answered}%`, backgroundColor:"var(--light-green)"}}
								className={`${styles["progress-bar-child"]}`}>
									<small className={styles["global-tooltip"]}>{questionStatusNameMapping["correct"]}</small>
							</div>
						:
							null
					}
					{
						wrong > 0
						?
							<div 
								style={{width: `${wrong}%`, backgroundColor:"var(--light-orange)"}}
								className={`${styles["progress-bar-child"]}`}>
									<small className={styles["global-tooltip"]}>{questionStatusNameMapping["wrong"]}</small>
							</div>
						:
							null
					}
					{
						reread > 0
						?
							<div 
								style={{width: `${reread}%`, backgroundColor:"var(--refresh-violet)"}}
								className={`${styles["progress-bar-child"]}`}>
									<small className={styles["global-tooltip"]}>{questionStatusNameMapping["reread"]}</small>
							</div>
						:
							null
					}
					{
						unanswered > 0
						?
							<div 
								style={{width: `${unanswered}%`, backgroundColor:"var(--light-blue)"}}
								className={`${styles["progress-bar-child"]}`}>
									<small className={styles["global-tooltip"]}>{questionStatusNameMapping["unanswered"]}</small>
							</div>
						:
							null
					}
                </div>
            </>
        )
    }

	function displayCurrentTopicFileName(fileName){
		if(window_width >= 500){
			if(fileName.length <= 40){
				return fileName;
			} else {
				return `${fileName.substring(0, 40)}...`;
			}
		} else if (window_width < 500){
			return `${fileName.substring(0, 18)}...`;
		}
	}

	function getQuestionStatusColor(status){
		if (status === "correct"){
			return "var(--light-green)";
		} else if(status === "wrong"){
			return "var(--light-orange)";
		} else if (status === "unanswered"){
			return "var(--light-blue)";
		} else if (status === "reread"){
			return "var(--refresh-violet)";
		}
	}

	function addBoxShadowForQuestionStatus(status){
		return "var(--global-box-shadow)";
		// if (status === "unanswered"){
		// 	return "var(--global-box-shadow)";
		// } else {
		// 	return "none";
		// }
	}

	return (
	<>
	{
		apiResponseJson && Object.keys(apiResponseJson).length > 0
		?
			<>
				<main className={`${styles["container"]}`}>
					<div className={`${styles["content-wrapper"]}`}>
						<div onClick={()=>{navigate("/concept")}} className={`${styles["back-btn"]}`}>
							<LeftArrowIcon/>
							<span>back to Concepts</span>
						</div>
						<div className={`${styles["question-list"]}`}>
							<div className={`${styles["topic-header"]}`}>
								<div className={`${styles["topic-header-left"]}`}>
									<div className={`${styles["topic-header-title"]}`}>
										{apiResponseJson.topic_name}
									</div>
									<div className={`${styles["topic-header-description"]}`}>
										<p>Pages {apiResponseJson.start_page} - {apiResponseJson.end_page}</p>
										<p>|</p>
										<p>{displayCurrentTopicFileName(apiResponseJson.resource_name)}</p>
									</div>
								</div>
								<div className={`${styles["topic-header-right"]}`}>
									<div className={`${styles["topic-header-progress-bar"]}`}>
										{showProgessBar({
											answered: apiResponseJson.num_correct_questions,
											wrong: apiResponseJson.num_wrong_questions,
											unanswered: apiResponseJson.num_unanswered_questions,
											reread: apiResponseJson.num_reread_questions
										})}
									</div>
									<div className={`${styles["topic-header-question-count"]}`}>
										{apiResponseJson.question_list.length} questions
									</div>
								</div>
							</div>
							<div className={`${styles["question-list-header"]}`}>
								<div className={`${styles["header-column-1"]}`}>
									Questions
								</div>
								<div className={`${styles["header-column-2"]}`}>
									Status
								</div>
							</div>
							{
								apiResponseJson.question_list.length > 0
								?
									<>
										{apiResponseJson.question_list.map((questionItem, questionIndex)=>(
											<div 
												className={`${styles["question-card"]}`} 
												key={questionIndex}
												onClick={()=>{navigate(`/quiz?questionId=${questionItem.question_id}&redirect=conceptQuestion&topic_id=${apiResponseJson.topic_id}&resource_id=${apiResponseJson.resource_id}`)}}
											>
												<div className={`${styles["question-card-left"]}`}>
													<div className={`${styles["question-title"]}`}>
														{questionItem.question_content.question}
													</div>
												</div>
												<div className={`${styles["question-card-right"]}`}>
													<aside 
														style={{
															backgroundColor: getQuestionStatusColor(questionItem.question_status),
															boxShadow: addBoxShadowForQuestionStatus(questionItem.question_status)
														}}>
															<small className={styles["global-tooltip"]}>{questionStatusNameMapping[questionItem.question_status]}</small>
													</aside>										
												</div>
											</div>
										))}
									</>
								:
									<></>
							}
						</div>
					</div>
				</main>
			</>
		:
		null
	}
	<LoaderSpinner data={showLoadingGif}></LoaderSpinner>
	</>
	);
};
