import { useState, useEffect, useRef, useLayoutEffect, useContext } from "react";
// import "./ConceptNew.scss";
import style from "./ConceptRevamped.module.scss";
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import lockIcon from "../../assets/lock.png";
// import padlockIcon from "../../assets/padlock.png";
import padlockIcon from "../../assets/lock.png";
import {
    getApiUrl,
    getUserAuthToken,
    getAllConcepts,
    listAllUnskippedResources,
    conceptRenameAPI,
    resourceRenameAPI,
    ExamSpaceUploadResource,
    GetUserInfoAPI,
    getAllExamSpaces,
    getNewTopicsAPI,
} from "../../helpers/requests";
import { useNavigate } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import threeDotIcon from "../../assets/three-dot-menu-icon.png"
import closeIcon from "../../assets/small-close.png"
import smallSVG from "../../assets/loading-svg-orange.svg"
import uploadIcon from "../../assets/upload-white-icon.png"
import loadingsmall from "../../assets/loading-svg-orange.svg"
// import downArrowIcon from "../../assets/new/down-arrow.svg"
import {ReactComponent as EyeIcon} from "../../assets/new/eye.svg"
import {ReactComponent as PlayIcon} from "../../assets/new/play.svg"
import rocketIcon from "../../assets/new/rocket.svg"
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { CurrentUserInfo } from "../../Components/ContextStore/ContextStore";
import ExamSpacePopup from "../../Components/ExamSpacePopup/ExamSpacePopup";
import FileUploadComponent from '../../Components/FileUploadComponent/FileUploadComponent';
import { ReactComponent as PlayIconWithCircle} from "../../assets/new/playIconWithCircle.svg"
import { ReactComponent as SearchIcon} from "../../assets/new/search-black-icon.svg"
import { ReactComponent as FolderIcon} from "../../assets/new/folder.svg"
import { ReactComponent as LoadingIcon} from "../../assets/loading-svg.svg"
import { ReactComponent as DownArrowIcon} from "../../assets/new/down-arrow.svg"
import { ReactComponent as TopicIcon} from "../../assets/new/topic-icon.svg"
import { ReactComponent as BulbIcon} from "../../assets/new/bulb-icon.svg"
import { set } from "date-fns";

export default function ConceptRevamped() {
    // page initialize
    const [resource_available, set_resource_available] = useState(true);
    const [topic_available, set_topic_available] = useState(true);

    const [data_available, set_data_available] = useState(true);
    const [question_available, set_question_available] = useState(true);
    // check for resource topic and topic question is genertated
    const isAllResourceTopicGenerated = useRef(false);
    const isAllTopicQuestionGenerated = useRef(false);



    const { sharedUserInfo, setSharedUserInfo } = useContext(CurrentUserInfo);
    const global_default_examspace_id = useRef("");
    
    var token = getUserAuthToken();
    var apiUrl = getApiUrl();
    const navigate = useNavigate();
    const [conceptdata, setConceptData] = useState([]);
    const [filterdata, setFilterData] = useState([]);
    const [newTopicsData, setNewTopicsData] = useState([]);
    const [resourceid, setResourceId] = useState("");
    const [searchText, setSearchText] = useState("");
    const [tempInterval, setTempInterval] = useState(0);
    const [displayLoadingUI, setDisplayLoadingUI] = useState(false);
    const [plan_type, set_plan_type] = useState(localStorage.getItem("plan_type"));
    const [question_count, set_question_count] = useState(null);
    const [topic_gif_visible, set_topic_gif_visible] = useState(false);
    const [concept_json_data_backup, set_concept_json_data_backup] = useState(false);
    const [showExamspacePopup, setShowExamspacePopup] = useState("false");
    const [examspaceData, setExamspaceData] = useState([]);
    const [defaultExamspace, setDefaultExamspace] = useState({});
    const [chapters_topics_btn, set_chapters_topics_btn] = useState("chapters");
    const [window_width, set_window_width] = useState(window.innerWidth);
    
    const mobileContextMenuVisibility = useRef(null);
    const mobileResponsiveTopicId = useRef(null);
    const setIntervalIdList = useRef([]);
    const conceptQuestionGenerationIntervalId = useRef(null);
    const conceptQuestionListIntervalId = useRef(null);
    const selectedResourceId = useRef("");
    const editConceptTitleOriginalInfo = useRef(null);
    const modifiedConceptTitle = useRef(null);
    const editResourceTitleOriginalInfo = useRef(null);
    const modifiedResourceTitle = useRef(null);
    const fileUploadRef = useRef(null);
    const listOfResourcesOpened = useRef([]);
    const searchBoxInputElement = useRef(null);
    let questionStatusNameMapping = {
		"correct": "Correct",
		"wrong": "Wrong",
		"unanswered": "New",
		"reread": "Refresh"
	}
    
    // mouse click event listener
    useEffect(()=>{
        setDisplayLoadingUI(true)
        proxyCallGetDefaultExamspaceIdUtility()
        const handleClick = (event) => {
            if(editConceptTitleOriginalInfo.current !== null){
                if(event.target.className !== style["resource-topic-card-topic-title"]){
                    if(modifiedConceptTitle.current === editConceptTitleOriginalInfo.current["concept-title"]){
                        editConceptTitleOriginalInfo.current = null;
                        modifiedConceptTitle.current = null;
                        resetConceptTitle();
                    } else {
                        handleConceptRename();
                    }
                }
            }
            if(editResourceTitleOriginalInfo.current !== null){
                if(event.target.className !== style["resource-card-title"]){
                    if(modifiedResourceTitle.current === editResourceTitleOriginalInfo.current["resource-title"]){
                        editResourceTitleOriginalInfo.current = null;
                        modifiedResourceTitle.current = null;
                        resetResourceTitle();
                    } else {
                        handleResourceRename();
                    }
                }
            }
            if(window_width < 1000){
                if(!event.target.closest(`.${style["search-container"]}`)){     
                    console.log('yes', event.target)
                    searchBoxInputElement.current.style.display = "none";
                }
            }
        };
        document.addEventListener('click', handleClick);

        const handleResize = () => {
            set_window_width(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return()=>{
            document.removeEventListener('click', handleClick);
            setIntervalIdList.current.forEach((intervalId)=>{
                clearInterval(intervalId);
            });        
            clearInterval(conceptQuestionGenerationIntervalId.current);
            clearInterval(conceptQuestionListIntervalId.current);
            window.removeEventListener("resize", handleResize);
            localStorage.removeItem("selectedResourceIdArray");
        }
    },[sharedUserInfo]);
    
    async function proxyCallGetDefaultExamspaceIdUtility(){
        let response = await getDefaultExamspaceIdUtility();
        checkEmptyState();
    }

    function closeExamSpacePopup() {
        setShowExamspacePopup("false")
    }

    async function getDefaultExamspaceIdUtility(){
        return new Promise( async(resolve, reject) => {
            let default_examspace_id = "";
            if( Object.keys(sharedUserInfo).length > 0){
                default_examspace_id = sharedUserInfo.default_examspace_id;
                global_default_examspace_id.current = sharedUserInfo.default_examspace_id;
                resolve(default_examspace_id);
            } 
            else {
                let getResponse = await GetUserInfoAPI()
                if (getResponse.status) {
                    if(getResponse.data.length > 0){
                        if(Object.keys(getResponse.data[0]).length > 0){
                            setSharedUserInfo(getResponse.data[0]);
                            default_examspace_id = getResponse.data[0]["default_examspace_id"];
                            global_default_examspace_id.current = getResponse.data[0]["default_examspace_id"];
                            resolve(default_examspace_id);
                        }
                    }
                }
            }
            
        });
    } 


    async function checkEmptyState(){
        try{
            let examspace_api_response = await getAllExamSpaces();
            setDisplayLoadingUI(false)
            if (examspace_api_response.data && examspace_api_response.data.length > 0) {
                console.log("file: ConceptRevamped.jsx:168 ~ checkEmptyState ~ examspace_api_response.data.length:", examspace_api_response.data.length)
                let selectedExamspaceData = examspace_api_response.data.find(item => item.id === global_default_examspace_id.current);
                console.log("file: ConceptRevamped.jsx:170 ~ checkEmptyState ~ selectedExamspaceData:", selectedExamspaceData)
                // data examspace component
                setExamspaceData(examspace_api_response.data)
                const defaultExamspaceDict = examspace_api_response.data.filter(obj => obj.is_default === true);
                console.log("file: ConceptRevamped.jsx:172 ~ checkEmptyState ~ defaultExamspaceDict:", defaultExamspaceDict)
                setDefaultExamspace(defaultExamspaceDict[0])
                if (selectedExamspaceData["resources"].length > 0) {
                    set_resource_available(true);

                    let conceptResponse = await getAllConcepts(global_default_examspace_id.current);
                    console.log("file: ConceptRevamped.jsx:177 ~ checkEmptyState ~ conceptResponse:")
                    if(conceptResponse.status){
                        if(conceptResponse.data.length > 0){
                            console.log("file: ConceptRevamped.jsx:180 ~ checkEmptyState ~ conceptResponse.data.length:", conceptResponse.data.length)
                            set_topic_available(true);
                            setConceptData(conceptResponse.data);
                            setFilterData(conceptResponse.data);
                            setTimeout(()=>{
                                if(listOfResourcesOpened.current.length <= 0){
                                    if(conceptResponse.data[0].topic_list.length > 0){
                                        listOfResourcesOpened.current.push(conceptResponse.data[0].resource_id)
                                    }
                                    openFileContent()
                                } else{
                                    openFileContent()
                                }
                            },100)               

                            const isResourceTopicGenerated = conceptResponse.data.every(resource => resource.topic_list.length > 0);
                            const isTopicQuestionGenerated = conceptResponse.data.every(resource =>
                                resource.topic_list
                                    .filter(topic => topic.status === "pending")
                                    .every(topic => topic.total_question_count > 0)
                            );              
                            isAllResourceTopicGenerated.current = isResourceTopicGenerated;
                            console.log("file: ConceptRevamped.jsx:203 ~ checkEmptyState ~ isAllResourceTopicGenerated.current:", isAllResourceTopicGenerated.current)
                            isAllTopicQuestionGenerated.current = isTopicQuestionGenerated;
                            console.log("file: ConceptRevamped.jsx:205 ~ checkEmptyState ~ isAllTopicQuestionGenerated.current:", isAllTopicQuestionGenerated.current)
                            
                            if(isAllResourceTopicGenerated.current === true && isAllTopicQuestionGenerated.current === true){
                                console.log("file: ConceptRevamped.jsx:208 ~ checkEmptyState ~ clearing interval pollIntervalId.current:", setIntervalIdList.current)
                                setIntervalIdList.current.forEach((intervalId)=>{
                                    clearInterval(intervalId);
                                });
                            } else {
                                if(setIntervalIdList.current.length <= 0){
                                    continuousPollFucntion();
                                }
                            }
                        } else {
                            set_topic_available(false);
                            if(setIntervalIdList.current.length <= 0){
                                continuousPollFucntion();
                            }                            
                        }                     
                    } else {
                        set_topic_available(false);
                        toast.error(conceptResponse.message, {
                            position: toast.POSITION.TOP_CENTER,
                        });                        
                    }
                } else {
                    set_resource_available(false);
                    set_topic_available(false);
                } 
            } else {
                toast.error(examspace_api_response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
            
        } catch (error) {
            console.error("concept", error)
        }
    }


    function continuousPollFucntion(){
        let timeDuration  = 0;
        let tempIntervalId = setInterval(async ()=>{
            timeDuration += 20;
            if(timeDuration >= 600){
                setIntervalIdList.current.forEach((intervalId)=>{
                    clearInterval(intervalId);
                });                    
            }
            getConceptsData();
        }, 20000);
        setIntervalIdList.current.push(tempIntervalId);
    }


    function openFileContent(){
        listOfResourcesOpened.current.forEach((resourceId)=>{
            topicListOpenOnly(resourceId)
        });
    }


    function topicListOpenOnly(res_id){
        let topic_list_element = document.getElementById(`resource-card-topic-list-${res_id}`);
        let res_triangle_element = document.getElementById(`resource-card-open-handle-${res_id}`);
        try {
            if(topic_list_element.style.display !== null | topic_list_element.style.display !== undefined){
            topic_list_element.style.display = "block";
            res_triangle_element.style.rotate = "180deg";
            }
        } catch(e){
            console.error(e)
        }
    }


    async function getConceptsData(){
        let conceptResponse = await getAllConcepts(global_default_examspace_id.current);
        setDisplayLoadingUI(false)
        if(conceptResponse.status){
            if(conceptResponse.data.length > 0){
                set_topic_available(true);
                setConceptData(conceptResponse.data);
                setFilterData(conceptResponse.data);
                setTimeout(()=>{
                    if(listOfResourcesOpened.current.length <= 0){
                        listOfResourcesOpened.current.push(conceptResponse.data[0].resource_id)
                        openFileContent()
                    } else{
                        openFileContent()
                    }
                },100)  

                const isResourceTopicGenerated = conceptResponse.data.every(resource => resource.topic_list.length > 0);
                const isTopicQuestionGenerated = conceptResponse.data.every(resource =>
                    resource.topic_list
                        .filter(topic => topic.status === "pending")
                        .every(topic => topic.total_question_count > 0)
                );        
                isAllResourceTopicGenerated.current = isResourceTopicGenerated;
                console.log("file: ConceptRevamped.jsx:304 ~ getConceptsData ~ isAllResourceTopicGenerated:", isAllResourceTopicGenerated)
                isAllTopicQuestionGenerated.current = isTopicQuestionGenerated;
                console.log("file: ConceptRevamped.jsx:306 ~ getConceptsData ~ isAllTopicQuestionGenerated:", isAllTopicQuestionGenerated)
                if(isAllResourceTopicGenerated.current === true && isAllTopicQuestionGenerated.current === true){
                    console.log("file: ConceptRevamped.jsx:308 ~ getConceptsData ~ clearing interval:", setIntervalIdList.current)
                    setIntervalIdList.current.forEach((intervalId)=>{
                        clearInterval(intervalId);
                    });
                }   

            } else {
                set_topic_available(false);
            }       
        } else {
            set_topic_available(false);
            toast.error(conceptResponse.message, {
                position: toast.POSITION.TOP_CENTER,
            });                        
        }        
    }  

    async function handleConceptRename(){
        let body = {
            "concept_id": editConceptTitleOriginalInfo.current["concept-id"],
            "concept_title": modifiedConceptTitle.current
        }
        if(modifiedConceptTitle.current.toString().length >= 3){
            let apiResponse = await conceptRenameAPI(body);
            if(apiResponse){
                editConceptTitleOriginalInfo.current = null;
                modifiedConceptTitle.current = null;
                getConceptsData()
                resetConceptTitle()
            }
        } else {
            toast.error("Concept title must be atleast 3 characters", {
                position: toast.POSITION.TOP_CENTER,
            });
            setConceptData([])
            getConceptsData()
            editConceptTitleOriginalInfo.current = null;
            modifiedConceptTitle.current = null;
            setTimeout(()=>{
                openFileContent()
                // let selectedResourceId = localStorage.getItem("lastSelectedResourceId");
                // if(selectedResourceId != null && selectedResourceId != undefined){
                //     openTopicList(selectedResourceId)
                // }
            },1000)
        }
    }


    async function handleResourceRename(){
        let body = {
            "resource_id": editResourceTitleOriginalInfo.current["resource-id"],
            "resource_title": modifiedResourceTitle.current
        }
        if(modifiedResourceTitle.current.toString().length >= 3){
            let apiResponse = await resourceRenameAPI(body);
            if(apiResponse){
                editResourceTitleOriginalInfo.current = null;
                modifiedResourceTitle.current = null;
                getConceptsData()
                resetResourceTitle()
            }
        } else {
            toast.error("File title must be atleast 3 characters", {
                position: toast.POSITION.TOP_CENTER,
            });        
            setConceptData([])
            getConceptsData()
            editResourceTitleOriginalInfo.current = null;
            modifiedResourceTitle.current = null;
            setTimeout(()=>{
                openFileContent()
                // let selectedResourceId = localStorage.getItem("lastSelectedResourceId");
                // if(selectedResourceId != null && selectedResourceId != undefined){
                //     openTopicList(selectedResourceId)
                // }
            },1000)        
        }
    }




    const handleSearch = (event) => {
        setIntervalIdList.current.forEach((intervalId)=>{
            clearInterval(intervalId);
        });
        let searchKeyword = event.target.value;
        console.log("00000000000000000:", searchKeyword)
        setSearchText(searchKeyword);
        try {
            if(searchKeyword === ""){
                getConceptsData();
            } else {
                let filteredResourceData = []
                if(filterdata.length > 0){
                    filterdata.forEach((resource) => {
                        let filtered_topic_list = []
                        console.log("1111111111", filtered_topic_list)
                        resource.topic_list.forEach((topic) => {
                            if(topic.name.toLowerCase().includes(searchKeyword.toLowerCase())){
                                console.log("222222222222", topic)
                                filtered_topic_list.push(topic)
                            }
                        })
                        console.log("3333333333333333", filtered_topic_list)
                        if(filtered_topic_list.length > 0){
                            filteredResourceData.push({
                                ...resource,
                                topic_list: filtered_topic_list
                            })
                        }
                    })
                }
                console.log("4444444444444444444444444", filteredResourceData)
                setConceptData(filteredResourceData);
                filteredResourceData.forEach((resource)=>{
                    topicListOpenOnly(resource.resource_id)
                });
            }        
        } catch (error) {
            console.error("Error in handleSearch:", error);
        }
    };
    
    function openMobileContextMenu(target){
        
        let menu_element = target.parentNode.parentNode.parentNode.nextSibling;
        
        if(menu_element.style.display === "block"){
            menu_element.style.display = "none";
        } else if(menu_element.style.display === "" || menu_element.style.display === "none"){
            let menu_list = document.getElementsByClassName("concept-responsive-context-menu-container");
            Array.from(menu_list).forEach((element) => {
                element.style.display = "none";
            });
            menu_element.style.display = "block";
        }
    }
    
    function CloseMobileContextMenu(target){
        let menu = target.parentNode.parentNode;
        menu.style.display = "none";
    }
    
    function handleUploadSuccess(response){
        checkEmptyState();
    }
    
    function triggerFileUpload() {
        console.log(global_default_examspace_id.current)
        fileUploadRef.current.triggerFileInput();
    }
    
    function openTopicList(res_id){
        let topic_list_element = document.getElementById(`resource-card-topic-list-${res_id}`);
        let res_triangle_element = document.getElementById(`resource-card-open-handle-${res_id}`);
        try {
            if(topic_list_element.style.display !== null | topic_list_element.style.display !== undefined){
                if(topic_list_element.style.display === "" || topic_list_element.style.display === "none" ){
                    topic_list_element.style.display = "block";
                    res_triangle_element.style.rotate = "180deg";
                    listOfResourcesOpened.current.push(res_id);
                } else {
                    topic_list_element.style.display = "none";
                    res_triangle_element.style.rotate = "0deg";
                    const newArray = listOfResourcesOpened.current.filter(item => item !== res_id);
                    listOfResourcesOpened.current = newArray;
                }
            }
        } catch(e){
            console.error(e)
        }
    }
    
    
    async function handleConceptTitleEdit(event, conceptInfo){
        resetConceptTitle(event);
        if(editConceptTitleOriginalInfo.current !== null){
            if(modifiedConceptTitle.current !== editConceptTitleOriginalInfo.current["concept-title"]){
                let body = {
                    "concept_id": editConceptTitleOriginalInfo.current["topic_id"],
                    "concept_title": modifiedConceptTitle.current
                }
                if(modifiedConceptTitle.current.toString().length >= 3){
                    let apiResponse = await conceptRenameAPI(body);
                    if(apiResponse){
                        // new value set
                        getConceptsData()
                        setConceptTitleInfo(conceptInfo)
                    }
                } else {
                    toast.error("Concept title must be atleast 3 characters", {
                        position: toast.POSITION.TOP_CENTER,
                    });          
                    setConceptData([])
                    getConceptsData()
                    setTimeout(()=>{
                        let selectedResourceId = localStorage.getItem("lastSelectedResourceId");
                        if(selectedResourceId != null && selectedResourceId != undefined){
                            // openTopicList(selectedResourceId)
                            editConceptTitleOriginalInfo.current = null;
                            modifiedConceptTitle.current = null;
                        }
                        openFileContent()
                    },1000)
                }
            } else {
                setConceptTitleInfo(conceptInfo)
            }
        } else{
            setConceptTitleInfo(conceptInfo)
        }
    }
    
    function setConceptTitleInfo(conceptInfo){
        editConceptTitleOriginalInfo.current = null;
        modifiedConceptTitle.current = null;
        editConceptTitleOriginalInfo.current = {
            "concept-id": conceptInfo.topic_id,
            "concept-title": conceptInfo.name,
        }
        modifiedConceptTitle.current = conceptInfo.name
    }
    function resetConceptTitle(event=undefined){
        // let tags = document.getElementsByClassName(style["resource-topic-card-topic-title"]);
        // Array.from(tags).forEach((element) => {
        //     element.style.boxShadow = "";
        //     element.style.backgroundColor = "#fff";
        //     element.style.padding = "0px";
        // });
        // if(event !== undefined){
        //     event.target.style.boxShadow = "rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px";
        //     event.target.style.backgroundColor = "#fff";
        //     event.target.style.padding = "0px 5px";
        // }
    }
    async function handleResourceTitleEdit(event, resourceInfo){
        resetResourceTitle(event);
        if(editResourceTitleOriginalInfo.current !== null){
            if(modifiedResourceTitle.current !== editResourceTitleOriginalInfo.current["resource-title"]){
                let body = {
                    "resource_id": editResourceTitleOriginalInfo.current["resource-id"],
                    "resource_title": modifiedResourceTitle.current
                }
                if(modifiedResourceTitle.current.toString().length >= 3){
                    let apiResponse = await resourceRenameAPI(body);
                    if(apiResponse){
                        // new value set
                        getConceptsData()
                        setResourceTitleInfo(resourceInfo)
                    }
                } else {
                    toast.error("File title must be atleast 3 characters", {
                        position: toast.POSITION.TOP_CENTER,
                    });          
                    setConceptData([])
                    getConceptsData()
                    setTimeout(()=>{
                        let selectedResourceId = localStorage.getItem("lastSelectedResourceId");
                        if(selectedResourceId != null && selectedResourceId != undefined){
                            // openTopicList(selectedResourceId)
                            editResourceTitleOriginalInfo.current = null;
                            modifiedResourceTitle.current = null;
                        }
                        openFileContent()
                    },1000)
                }
            } else {
                setResourceTitleInfo(resourceInfo)
            }
        } else{
            setResourceTitleInfo(resourceInfo)
        }
    }
    
    function setResourceTitleInfo(resourceInfo){
        editResourceTitleOriginalInfo.current = null;
        modifiedResourceTitle.current = null;
        editResourceTitleOriginalInfo.current = {
            "resource-id": resourceInfo.resource_id,
            "resource-title": resourceInfo.resource_title,
        }
        modifiedResourceTitle.current = resourceInfo.resource_title
    }
    function resetResourceTitle(event=undefined){
        // let tags = document.getElementsByClassName(style["resource-card-title"]);
        // Array.from(tags).forEach((element) => {
        //     element.style.boxShadow = "";
        //     element.style.backgroundColor = "#fff";
        //     element.style.padding = "0px";
        // });
        // if(event !== undefined){
        //     event.target.style.boxShadow = "rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px";
        //     event.target.style.backgroundColor = "#fff";
        //     event.target.style.padding = "0px 5px";
        // }
    }

    const fetchExamSpaceData = async () => {
        let examSpaceResponse = await getAllExamSpaces();
        if (examSpaceResponse.status) {
            if (examSpaceResponse.data.length) {
                setExamspaceData(examSpaceResponse.data)
                const defaultExamspaceDict = examSpaceResponse.data.filter(obj => obj.is_default === true);
                setDefaultExamspace(defaultExamspaceDict[0])
            }
        }
    }

    // ui function
    function showLoadingGif(text){
        return(
            <>
                <div className={style["loading-gif-box"]}>
                    <img src={smallSVG} alt="loading gif" />
                    <p>{text}</p>
                </div>
            </>
        )
    }
    function showProgessBar({answered, unanswered, wrong, reread}){
        if(answered === 0 && unanswered === 0 && wrong === 0 && reread === 0){
            unanswered = 100;
        }
        return(
            <>
                <div className={style["progress-bar"]}>
                    {
                        answered > 0 
                        ?
                            <div 
                                style={{width:`${answered}%`, backgroundColor:"var(--light-green)"}}
                                className={`${style["progress-bar-child"]}`}>
                                    <small className={style["global-tooltip"]}>{questionStatusNameMapping["correct"]}</small>
                            </div>
                        : 
                            <></>
                    }
                    {
                        wrong > 0
                        ?
                            <div 
                                style={{width: `${wrong}%`, backgroundColor:"var(--light-orange)"}}
                                className={`${style["progress-bar-child"]}`}>
                                    <small className={style["global-tooltip"]}>{questionStatusNameMapping["wrong"]}</small>
                            </div>
                        :
                            <></>
                    }
                    {
                        reread > 0
                        ?
                            <div 
                                style={{width: `${reread}%`, backgroundColor:"var(--refresh-violet)"}}
                                className={`${style["progress-bar-child"]}`}>
                                    <small className={style["global-tooltip"]}>{questionStatusNameMapping["reread"]}</small>
                            </div>
                        :
                        <></>
                    }
                    {
                        unanswered > 0
                        ?
                            <div 
                                style={{width: `${unanswered}%`, backgroundColor:"var(--pearl-white)"}}
                                className={`${style["progress-bar-child"]}`}>
                                    <small className={style["global-tooltip"]}>{questionStatusNameMapping["unanswered"]}</small>
                            </div>
                        :
                        <></>
                    }
                </div>
            </>
        )
    }
    function showGoUnlimitedBtn(){
        return(
            <>
                <div onClick={()=>{navigate("/pushmode")}} className={style["go-unlimited-btn"]}>
                    <img src={rocketIcon} alt="rocket icon" />
                    <p>Go unlimited</p>
                </div>
            </>
        )
    }

    function searchBoxToggleInput(){
        searchBoxInputElement.current.style.display = "block";
        // if(searchBoxInputElement.current.style.display === "none" | searchBoxInputElement.current.style.display === ""){
        // } else {
        //     searchBoxInputElement.current.style.display = "none";
        // }
    }
    function SearchBoxComponent() { 
        return(
            <>
                <div className={style["search-container"]}>
                    <SearchIcon onClick={()=>{searchBoxToggleInput()}}/>
                    <div className={style["search-input-wrapper"]}>
                        <input ref={searchBoxInputElement} onChange={handleSearch}  value={searchText}  type="text" placeholder='Search'/>
                    </div>
                </div>            
            </>
        )
    }
    function checkExamspaceNameLength(name){
        if(name.length <= 25){
            return name;
        } else {
            return `${name.substring(0, 25)}...`;
        }
	}
    function ExamspaceSpaceFolderComponent(){ 
        return(
            <>
                <div className={`${style['examspace-folder']}`} onClick={(e)=>{setShowExamspacePopup("examSpace")}}>
                    <FolderIcon/>
                    {window_width >= 1000 ? <p>{Object.keys(defaultExamspace).length > 0 ? checkExamspaceNameLength(defaultExamspace.title) : "examspace"}</p> : <></>}
                </div>
                <ExamSpacePopup data={examspaceData} popup={showExamspacePopup} close={closeExamSpacePopup} reCallGet={fetchExamSpaceData} />      
            </>
        )
    }

    const chapterTopicToggleMobile = () => {
        if(chapters_topics_btn === "chapters"){
            set_chapters_topics_btn("topics")
        } else {
            set_chapters_topics_btn("chapters")
        }
    }
    function switchTopicsType({topics_type}){
        set_chapters_topics_btn( (previous_value) => {
                if(previous_value !== topics_type){
                    setDisplayLoadingUI(true)
                    if(topics_type === "chapters"){
                        getConceptsData()
                    } else if(topics_type === "topics"){
                        InitializeGetNewTopicsData()
                    }
                    return topics_type;
                } else {
                    return previous_value;
                }
            }
        )
    }
    function ChapterTopicToggleComponent() { 
        return(
            <>
                <div className={style["chapters-topics-toggle-btn"]}>
                    {
                        window_width >= 1000
                        ?
                            <>
                                <article
                                    onClick={()=>{switchTopicsType({topics_type: "chapters"})}}
                                    className={chapters_topics_btn === "chapters" ? `${style["chapters-topics-toggle-btn-selected"]}` : ""}
                                >
                                    Chapters
                                </article>
                                <article
                                    onClick={()=>{switchTopicsType({topics_type: "topics"})}}
                                    className={chapters_topics_btn === "topics" ? `${style["chapters-topics-toggle-btn-selected"]}` : ""}
                                >
                                    Topics
                                </article>
                            </>
                        :
                            <>
                                {chapters_topics_btn === "chapters" ? <TopicIcon onClick={chapterTopicToggleMobile}/> : <></>}
                                {chapters_topics_btn === "topics" ? <BulbIcon onClick={chapterTopicToggleMobile}/> : <></>}
                            </>
                    }
                </div>
            </>
        )
    }

    async function InitializeGetNewTopicsData(){
        try{
            let examspace_api_response = await getAllExamSpaces();
            setDisplayLoadingUI(false)
            if (examspace_api_response.data && examspace_api_response.data.length > 0) {
                let selectedExamspaceData = examspace_api_response.data.find(item => item.id === global_default_examspace_id.current);
                // data examspace component
                setExamspaceData(examspace_api_response.data)
                const defaultExamspaceDict = examspace_api_response.data.filter(obj => obj.is_default === true);
                setDefaultExamspace(defaultExamspaceDict[0])
                if (selectedExamspaceData["resources"].length > 0) {
                    set_resource_available(true);

                    let newTopicsResponse = await getNewTopicsAPI(global_default_examspace_id.current);
                    if(newTopicsResponse.status){
                        if(newTopicsResponse.data.length > 0){
                            set_topic_available(true);
                            setNewTopicsData(newTopicsResponse.data);
                            setFilterData(newTopicsResponse.data);            

                            const isTopicQuestionGenerated = newTopicsResponse.data.every(newTopic => newTopic.total_question_count > 0);            
                            isAllTopicQuestionGenerated.current = isTopicQuestionGenerated;

                            if(isAllTopicQuestionGenerated.current === true){
                                setIntervalIdList.current.forEach((intervalId)=>{
                                    clearInterval(intervalId);
                                });
                            } else {
                                if(setIntervalIdList.current.length <= 0){
                                    continuousPollNewTopics();
                                }
                            }
                        } else {
                            set_topic_available(false);
                            if(setIntervalIdList.current.length <= 0){
                                continuousPollNewTopics();
                            }                            
                        }                     
                    } else {
                        set_topic_available(false);
                        toast.error(newTopicsResponse.message, {
                            position: toast.POSITION.TOP_CENTER,
                        });                        
                    }
                } else {
                    set_resource_available(false);
                    set_topic_available(false);
                } 
            } else {
                toast.error(examspace_api_response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
            
        } catch (error) {
            console.error("concept", error)
        }
    }

    function continuousPollNewTopics(){
        let timeDuration  = 0;
        let tempIntervalId = setInterval(async ()=>{
            timeDuration += 20;
            if(timeDuration >= 600){
                setIntervalIdList.current.forEach((intervalId)=>{
                    clearInterval(intervalId);
                });                    
            }
            getNewTopicsOnce();
        }, 20000);
        setIntervalIdList.current.push(tempIntervalId);        
    }

    async function getNewTopicsOnce(){
        let newTopicsResponse = await getNewTopicsAPI(global_default_examspace_id.current);
        if(newTopicsResponse.status){
            if(newTopicsResponse.data.length > 0){
                set_topic_available(true);
                setNewTopicsData(newTopicsResponse.data);
                setFilterData(newTopicsResponse.data);

                const isTopicQuestionGenerated = newTopicsResponse.data.every(newTopic => newTopic.total_question_count > 0);
                isAllTopicQuestionGenerated.current = isTopicQuestionGenerated;
                if(isAllTopicQuestionGenerated.current === true){
                    setIntervalIdList.current.forEach((intervalId)=>{
                        clearInterval(intervalId);
                    });
                }   

            } else {
                set_topic_available(false);
            }       
        } else {
            set_topic_available(false);
            toast.error(newTopicsResponse.message, {
                position: toast.POSITION.TOP_CENTER,
            });                        
        }        
    } 

return (
    <>
        <main className={style["concept-container"]}>
            <div className={style["concept-wrapper"]}>
                <div className={style["title-section"]}>
                    <div className={style["title-section-left"]}>
                        <h1 className={`${style["title-section-heading"]} global-h1`}>Questions</h1>
                    </div>
                    <div className={style["title-section-right"]}>
                        {ChapterTopicToggleComponent()}
                        {SearchBoxComponent()}
                        {ExamspaceSpaceFolderComponent()}
                    </div>
                </div>

                <section style={{display: resource_available === true && topic_available === true && displayLoadingUI === true ? "flex" : "none"}} className={style["loading-icon"]}>
                    <LoadingIcon/>
                </section>

                <section className={style["concept-empty-state-container"]} style={resource_available === false && topic_available === false? { display: "flex" } : { display: "none" }}>
                {/* <section className={style["concept-empty-state-container"]} style={false? { display: "flex" } : { display: "none" }}> */}
                    <div className={style["empty-state-content"]}>
                        {/* upload file UI */}
                        <div
                            className={style["empty-state-upload-file"]}
                        >
                            <p>Upload at least 1 document to see the main concepts to learn !!</p>
                            <FileUploadComponent
                                ref={fileUploadRef}
                                onUploadSuccess={handleUploadSuccess}
                                examspaceId={global_default_examspace_id.current}
                            />
                            <button onClick={triggerFileUpload}>
                                <img src={uploadIcon} alt="upload icon" />
                                Upload a file
                            </button>
                        </div>
                    </div>
                </section>

                <section className={style["concept-empty-state-container"]} style={resource_available === true && topic_available === false ? { display: "flex" } : { display: "none" }}>
                {/* <section className={style["concept-empty-state-container"]} style={true ? { display: "flex" } : { display: "none" }}> */}
                    <div className={style["empty-state-content"]}>
                        {/* Generate question UI */}
                        <div
                            className={style["empty-state-generate"]}
                        >
                            <img src={loadingsmall} alt="loading" />
                            <p>We are extracting the main concepts from your document</p>
                        </div>
                    </div>
                </section>


                {
                    resource_available === true && topic_available === true && displayLoadingUI === false && chapters_topics_btn === "chapters" && conceptdata.length > 0
                    // false > 0
                    ?
                        <>
                        <div className={style["resource-list"]}>
                        {
                            conceptdata.map((resourceItem, resourceIndex) => (
                                <article className={style["resource-card"]} key={resourceIndex}>
                                    <section className={style["resource-card-header"]}>
                                        <div className={style["resource-card-left"]}>
                                            <h2 
                                                className={style["resource-card-title"]}
                                                onClick={(e)=>{handleResourceTitleEdit(e, resourceItem); localStorage.setItem("lastSelectedResourceId", resourceItem.resource_id)}}
                                                onInput={(e)=>{
                                                    modifiedResourceTitle.current = e.target.innerText;
                                                }}
                                                contentEditable={true}
                                                suppressContentEditableWarning={true}
                                            >
                                                {resourceItem.resource_title}
                                            </h2>
                                        </div>
                                        {
                                            resourceItem.topic_list.length > 0
                                            // false
                                            ?
                                                <>
                                                    <div className={style["resource-card-right"]}>
                                                        <div className={style["resource-card-right-content"]}>
                                                            <div className={style["resource-card-score-box"]}>
                                                                {showProgessBar({
                                                                    answered: resourceItem.num_correct_questions,
                                                                    unanswered: resourceItem.num_unanswered_questions,
                                                                    wrong: resourceItem.num_wrong_questions,
                                                                    reread: resourceItem.num_reread_questions
                                                                })}
                                                            </div>
                                                            <div className={style["resource-card-resource-info"]}>
                                                                <p>{resourceItem.topics_count} chapters</p>
                                                                <p>{resourceItem.total_question_count} questions</p>
                                                            </div>
                                                        </div>
                                                        <div 
                                                            className={style["resource-card-open-handle"]}
                                                            id={`resource-card-open-handle-${resourceItem.resource_id}`}
                                                            onClick={(e)=>{openTopicList(resourceItem.resource_id)}}>
                                                            <DownArrowIcon/>
                                                        </div>
                                                    </div>
                                                </>
                                            :
                                                <>
                                                    <div className={style["resource-card-right"]}>
                                                        <div className={style["resource-topic-generating"]}>
                                                            {showLoadingGif("Loading your questions..")}
                                                        </div>                                                
                                                    </div>
                                                </>
                                        }
                                    </section>
                                    <div className={style["resource-card-topic-list"]} id={`resource-card-topic-list-${resourceItem.resource_id}`}>
                                        {
                                            resourceItem.topic_list.length > 0
                                            ?
                                                <>
                                                    {
                                                        resourceItem.topic_list.map((topicItem, topicIndex) => (
                                                            <div className={style["resource-topic-card"]} key={topicIndex}>
                                                                <div className={style["resource-topic-card-left"]} >
                                                                    <div 
                                                                        className={style["resource-topic-card-topic-title"]}
                                                                        contentEditable={true}
                                                                        suppressContentEditableWarning={true}
                                                                        onClick={(e)=>{handleConceptTitleEdit(e, topicItem); localStorage.setItem("lastSelectedResourceId", resourceItem.resource_id)}}
                                                                        onInput={(e)=>{
                                                                            modifiedConceptTitle.current = e.target.innerText                                           
                                                                        }}                                                                        
                                                                    > 
                                                                        {topicItem.name}
                                                                    </div>
                                                                    <div className={style["resource-topic-card-topic-pages"]}>
                                                                        pages {`${topicItem.start_page} - ${topicItem.end_page}`}
                                                                    </div>
                                                                </div>
                                                                <div className={style["resource-topic-card-right"]} >
                                                                    {
                                                                        topicItem.status === "pending"
                                                                        ?
                                                                            topicItem.total_question_count > 0
                                                                            ?
                                                                                <>
                                                                                    <div className={style["resource-topic-card-right-content"]}>
                                                                                        <div className={style["resource-topic-card-topic-scores"]}>
                                                                                            {showProgessBar({
                                                                                                answered: topicItem.num_correct_questions,
                                                                                                unanswered: topicItem.num_unanswered_questions,
                                                                                                wrong: topicItem.num_wrong_questions,
                                                                                                reread: topicItem.num_reread_questions
                                                                                            })}
                                                                                        </div>
                                                                                        <div className={style["resource-topic-card-btns"]}>
                                                                                            <div 
                                                                                                onClick={()=>{navigate( `/conceptquestion?topic_id=${topicItem.topic_id}&resource_id=${resourceItem.resource_id}`)}}
                                                                                                className={style["see-all-questions-btn"]}>
                                                                                                <EyeIcon/>
                                                                                                {window_width >= 1000 ? <p>See {topicItem.total_question_count} questions</p> : <></>}
                                                                                            </div>                                                                    
                                                                                            <div 
                                                                                                onClick={(e)=>{navigate(`/quiz?topicId=${topicItem.topic_id}`)}} 
                                                                                                className={style["practise-btn"]}>
                                                                                                {
                                                                                                    window_width >= 1000
                                                                                                    ?
                                                                                                        <>
                                                                                                            <PlayIcon/>
                                                                                                            <p>Practice</p>
                                                                                                        </>
                                                                                                    :
                                                                                                        <>
                                                                                                            <PlayIconWithCircle/>
                                                                                                        </>
                                                                                                }
                                                                                            </div>                                                                    
                                                                                        </div>                                                                   
                                                                                        {/* <div className={style["resource-topic-card-mobile-btns"]}>
                                                                                            <div className={style["see-all-questions-mobile-btn"]}>
                                                                                                <EyeIcon/>
                                                                                            </div>                                                                    
                                                                                            <div className={style["practise-mobile-btn"]}>
                                                                                                <img src={playIconWithCircle} alt="play icon" />   
                                                                                            </div>                                                                    
                                                                                        </div>*/}
                                                                                    </div>
                                                                                </>
                                                                            :
                                                                                <>
                                                                                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                                                                                        {showLoadingGif("Loading your questions..")}  
                                                                                    </div>
                                                                                </>
                                                                        :
                                                                            <>
                                                                                {showGoUnlimitedBtn()}
                                                                            </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </>
                                            :
                                                <>
                                                    <div className={style["resource-topic-generating"]}>
                                                        {showLoadingGif("Loading your topics..")}
                                                    </div>
                                                </>
                                        }
                                    </div>
                                </article>
                                )
                            )
                        }
                        </div>
                        </>
                    :
                        <></>
                }

                {
                    resource_available === true && topic_available === true && displayLoadingUI === false && chapters_topics_btn === "topics" && newTopicsData.length > 0
                    // false > 0
                    ?
                        <>
                        <div className={style["resource-list"]}>
                            <article className={style["resource-card"]}>
                                    {
                                        newTopicsData.length > 0
                                        ?
                                            <>
                                                {
                                                    newTopicsData.map((topicItem, topicIndex) => (
                                                        <div className={style["resource-topic-card"]} key={topicIndex}>
                                                            <div className={style["resource-topic-card-left"]} >
                                                                <div 
                                                                    className={style["resource-topic-card-topic-title"]}
                                                                    // contentEditable={true}
                                                                    // suppressContentEditableWarning={true}
                                                                    // onClick={(e)=>{handleConceptTitleEdit(e, topicItem); localStorage.setItem("lastSelectedResourceId", resourceItem.resource_id)}}
                                                                    // onInput={(e)=>{
                                                                    //     modifiedConceptTitle.current = e.target.innerText                                           
                                                                    // }}                                                                        
                                                                > 
                                                                    {topicItem.name}
                                                                </div>
                                                                {/* <div className={style["resource-topic-card-topic-pages"]}>
                                                                    pages {`${topicItem.start_page} - ${topicItem.end_page}`}
                                                                </div> */}
                                                            </div>
                                                            <div className={style["resource-topic-card-right"]} >
                                                                {
                                                                    TextTrackCue
                                                                    ?
                                                                        topicItem.total_question_count > 0
                                                                        ?
                                                                            <>
                                                                                <div className={style["resource-topic-card-right-content"]}>
                                                                                    <div className={style["resource-topic-card-topic-scores"]}>
                                                                                        {showProgessBar({
                                                                                            answered: topicItem.num_correct_questions,
                                                                                            unanswered: topicItem.num_unanswered_questions,
                                                                                            wrong: topicItem.num_wrong_questions,
                                                                                            reread: topicItem.num_reread_questions
                                                                                        })}
                                                                                    </div>
                                                                                    <div className={style["resource-topic-card-btns"]}>
                                                                                        <div 
                                                                                            // onClick={()=>{navigate( `/conceptquestion?topic_id=${topicItem.topic_id}&resource_id=${resourceItem.resource_id}`)}}
                                                                                            className={style["see-all-questions-btn"]}>
                                                                                            <EyeIcon/>
                                                                                            {window_width >= 1000 ? <p>See {topicItem.total_question_count} questions</p> : <></>}
                                                                                        </div>                                                                    
                                                                                        <div 
                                                                                            onClick={(e)=>{navigate(`/quiz?topicId=${topicItem.topic_id}`)}} 
                                                                                            className={style["practise-btn"]}>
                                                                                            {
                                                                                                window_width >= 1000
                                                                                                ?
                                                                                                    <>
                                                                                                        <PlayIcon/>
                                                                                                        <p>Practice</p>
                                                                                                    </>
                                                                                                :
                                                                                                    <>
                                                                                                        <PlayIconWithCircle/>
                                                                                                    </>
                                                                                            }
                                                                                        </div>                                                                    
                                                                                    </div>                                                                   
                                                                                    {/* <div className={style["resource-topic-card-mobile-btns"]}>
                                                                                        <div className={style["see-all-questions-mobile-btn"]}>
                                                                                            <EyeIcon/>
                                                                                        </div>                                                                    
                                                                                        <div className={style["practise-mobile-btn"]}>
                                                                                            <img src={playIconWithCircle} alt="play icon" />   
                                                                                        </div>                                                                    
                                                                                    </div>*/}
                                                                                </div>
                                                                            </>
                                                                        :
                                                                            <>
                                                                                <div style={{display: "flex", justifyContent: "flex-end"}}>
                                                                                    {showLoadingGif("Loading your questions..")}  
                                                                                </div>
                                                                            </>
                                                                    :
                                                                        <>
                                                                            {showGoUnlimitedBtn()}
                                                                        </>
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        :
                                            <>
                                            </>
                                    }
                            </article>
                        </div>
                        </>
                    :
                        <></>
                }
            </div>
        </main>
        
        {/* <ExamSpacePopup data={ExamSpacesList} popup={showExamspace} close={handleClosePopUp} reCallGet={getAllExamSpacesList} /> */}
        <ToastContainer />
    </>
);

}
