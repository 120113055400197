import React from 'react'
import { BiMessageSquare } from 'react-icons/bi';
import { CiMail } from 'react-icons/ci';
import { Link } from 'react-router-dom';
import './Privacy.scss'


const Terms = () => {
    return (
        <div className='main_privacy'>
            <div className="privacy_text">
                <div className="heading">Dende Terms of Use</div>
                <div className="para">Effective Date: 16th of May 2023</div>
            </div>



            <div className="privacy_text">
                <div className="heading">Acknowledgment</div>
                <div className="para">
                    Welcome to Dende Srl (“Company”, “we”, “us” or “our”), a technology company
                    specialising in artificial intelligence related products. Our goal is to create a better way for
                    people to learn through access to neural network models such as CHATGPT, and other AI
                    models. We aim to revolutionise the way people learn through customized information aided by
                    Artificial Intelligence (the “Services”).<br />
                    These terms of use (the “Terms”) apply to all users and others who access and use our mobile
                    app: (“Dende”), and our website www.dende.ai (the “Website”).<br />
                    <strong>
                        By using Dende, you agree to these Terms. Your continued use of Dende will confirm your
                        acceptance of these Terms.
                    </strong>
                </div>
                <div className="para">
                    You are accepting and agreeing to these Terms on behalf of yourself or the entity that you
                    represent in connection with the use of Dende. If you are using Dende on behalf of another
                    person or entity, you represent that you are authorized to accept these Terms on that person, or
                    entity’s behalf and that such person or entity agrees to be responsible to us if you or the other
                    person or entity violates these Terms.
                </div>
                <div className="para">
                    <strong>BY USING DENDE, YOU AFFIRM THAT YOU ARE OF LEGAL AGE TO ENTER
                        INTO THE TERMS.</strong> If you have any questions about these Terms or Dende, please contact us
                    via an email at info@dende.ai. For additional contact information, please, see Section 21 of these
                    Terms.
                </div>
                <div className="para">
                    NOTE: Dende uses Neural Network Models such as CHATGPT and other AI models (the
                    ”Models”) that allow users to answer prompts and carry on tasks such as create customized
                    questions, flash cards and answers assestment (the “Tasks”). In order to carry on the Tasks, users
                    shall submit texts, images, or other information (the “Contents”).
                </div>
            </div>
            <div className="how">How does it work?</div>
            <ul className="main_ul">
                <li>Users upload Contents on Dende.</li>
                <li>The Contents are sent for the purposes of prompting (input prompts) to an AI model to
                    perform a Task such, for example, the creation of flash cards tailored to the specific
                    Contents.</li>
                <li>These Tasks can be performed by a variety of third-party AI models and services that we
                    integrate with.</li>
                <li>After the AI services have completed their Task, the results (output prompts) are shared
                    back with the User.</li>
                <li>After the Tasks have been completed, all the prompts (both input and output) are stored
                    on our servers and we might use these prompts (input and output) to train and/or create
                    our artificial intelligence/products.</li>
                <li>We do not use your Personal Information (as defined in our Privacy Policy) to generally
                    train and/or create our separate artificial intelligence/products.</li>
            </ul>


            <div className="privacy_text">
                <div className="heading">1. Description Of Services</div>
                <div className="para">
                    Dende is a software program that acts as a front-end interface between users and the Models
                    allowing users to conveniently carry on several Tasks. Dende allows users to carry on tasks
                    such as creating customized questions, flash cards and answers assestment. From time to
                    time and without prior notice to you, we may change, expand, and improve Dende. We may
                    also, at any time, cease to continue operating part or all of Dende or selectively disable
                    certain features. Your use of Dende does not entitle you to the continued provision or
                    availability of it. Any modification or elimination of Dende or any particular features will be
                    done at our sole discretion.
                </div>
            </div>


            <div className="privacy_text">
                <div className="heading">2. Beta Services and Beta Use Limitations</div>
                <div className="para">
                    This Section governs your use of the Services or features that we offer on a research, alpha,
                    preview, early access, or beta basis (“Beta Services”). Beta Services are offered “as-is” to
                    allow testing and evaluation, and the Contents that you provide to Beta Services may be used
                    to improve the services. We make no representations or warranties for Beta Services,
                    including any warranty that Beta Services will be generally available, uninterrupted or
                    error-free, or that Content will be secure or not lost or damaged. Except to the extent
                    prohibited by law, the Company expressly disclaims all warranties for Beta Services,
                    including any implied warranties of satisfactory quality, fitness for a particular purpose,
                    non-infringement, or quiet enjoyment, and any warranties arising out of any course of
                    dealing or usage of trade.
                </div>
            </div>




            <div className="privacy_text">
                <div className="heading">3. Subscriptions</div>
                <div className="para">
                    <strong>3.1 Subscription Period</strong> <br />
                    The Services or some parts of the Services are available only with a paid subscription. You
                    will be billed in advance on a recurring and periodic basis (such as daily, weekly, monthly or
                    annually), depending on the type of subscription plan you select when purchasing the
                    subscription. At the end of each period, Your Subscription will automatically renew under the
                    exact same conditions unless you cancel it or the Company cancels it.
                </div>
                <div className="para">
                    <strong>3.2 Subscription Cancellations</strong> <br />
                    You may cancel your subscription renewal either through your account settings page or by
                    contacting the Company. You will not receive a refund for the fees you already paid for your
                    current subscription period and you will be able to access the Services until the end of your
                    current subscription period.
                </div>

                <div className="para">
                    <strong>3.3 Billing</strong> <br />
                    You shall provide the Company with accurate and complete billing information including full
                    name, address, state, zip code, telephone number, and a valid payment method information.
                    Should automatic billing fail to occur for any reason, the Company will issue an electronic
                    invoice indicating that you must proceed manually, within a certain deadline date, with the
                    full payment corresponding to the billing period as indicated on the invoice.
                </div>
                <div className="para">
                    <strong>3.4 Fee Changes</strong> <br />
                    The Company, in its sole discretion and at any time, may modify the subscription fees. Any
                    subscription fee change will become effective at the end of the then-current subscription
                    period. The Company will provide you with reasonable prior notice of any change in
                    subscription fees to give you an opportunity to terminate your subscription before such
                    change becomes effective. Your continued use of the Services after the subscription fee
                    change comes into effect constitutes your agreement to pay the modified subscription fee
                    amount.
                </div>
                <div className="para">
                    <strong>3.5 Refunds</strong> <br />
                    Except when required by law, paid subscription fees are non-refundable. Certain refund
                    requests for subscriptions may be considered by the Company on a case-by-case basis and
                    granted at the sole discretion of the Company.
                </div>
                <div className="para">
                    <strong>3.6 Free Trial</strong> <br />
                    The Company may, at its sole discretion, offer a subscription with a free trial for a limited
                    period of time. You may be required to enter your billing information in order to sign up for
                    the free trial. If you do enter your billing information when signing up for a free trial, you
                    will not be charged by the Company until the free trial has expired. On the last day of the free
                    trial period, unless you cancelled your subscription, You will be automatically changed the
                    applicable subscription fees for the type of subscription you have selected. At any time and
                    without notice, the Company reserves the right to (i) modify the terms and conditions of the
                    free trial offer, or (ii) cancel such free trial offer.
                </div>
            </div>




            <div className="privacy_text">
                <div className="heading">4. Eligibility</div>
                <div className="para">
                    <strong>General age limitation. </strong>  You must be at least 18 years of age to use Dende. If you are aware
                    of anyone younger than 18 using Dende, please contact us at info@dende.ai (for additional
                    contact information, please, see Section 21 of these Terms and we will take reasonable steps
                    to preclude such person from Dende use.You represent and warrant that you have not been previously suspended or removed from
                    using Dende. You further agree to use Dende in compliance with all applicable laws and to
                    provide only true and accurate information to us. Dende is not available to any users
                    previously prohibited from using it.
                </div>
            </div>

            <div className="privacy_text">
                <div className="heading">5. User Accounts and Account Security</div>
                <div className="para">
                    Dende features or functionalities will require you to register an account with us (“Account”).
                    By creating an Account, you agree to: (i) provide accurate, current, and complete information
                    for your Account, (ii) maintain and promptly update, as necessary, your Account information,
                    (iii) maintain the security of your Account login information, (iv) be responsible for all
                    activity that occurs via your account even if that activity is not by you or is without your
                    knowledge or consent, and (v) immediately notify us if you discover or otherwise suspect
                    any security breaches related to your Account. You are entirely responsible for maintaining
                    the confidentiality, security and control of your Account login information and for all
                    activities that occur in your Account. We are not liable for any loss or damage arising from
                    your failure to comply with the above requirements.
                </div>
                <div className="para">
                    You are responsible for safeguarding the password that you use to access the Services and for
                    any activities or actions under your password, whether your password is with our Services or
                    a third-party social media service. You agree not to disclose your password to any third party.
                    You may not use as a username the name of another person or entity or that is not lawfully
                    available for use, a name or trademark that is subject to any rights of another person or entity
                    other than you without appropriate authorization, or a name that is otherwise offensive,
                    vulgar or obscene.
                </div>
                <div className="para">
                    PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE
                    PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED
                    SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE
                    PROVIDERS.
                </div>
            </div>


            <div className="privacy_text">
                <div className="heading">6. Privacy</div>
                <div className="para">
                    Please refer to our Privacy Policy for information on how we collect, process and transfer
                    information about you when you use Dende. Transfer and storage of information about you,
                    whilst you use Dende, is governed by our Privacy Policy. You acknowledge and agree that
                    your use of Dende is subject to our Privacy Policy.
                </div>
            </div>

            <div className="privacy_text">
                <div className="heading">7. User Content</div>
                <div className="para">
                    Our Services allow you to submit Contents in order to carry on the Tasks. You are
                    responsible for the Contents that you submit to the Services, including its legality, reliability,
                    and appropriateness.
                </div>
                <div className="para">
                    By submitting and/or posting Contents to the Services, you grant us an irrevocable, exclusive
                    and royalty free right to use, modify, reproduce, and distribute the Contents for commercial
                    or non-commercial purposes. For example, you grant us the right to submit your Contents for
                    handling by third party APIs such as OpenAI's API.
                </div>
                <div className="para">
                    PLEASE NOTE THAT WE RETAIN ANY AND ALL THE RIGHTS TO ANY CONTENT
                    DERIVED FROM YOUR CONTENTS (I.E. DERIVED WORK) AND CONTAINED IN
                    THE TASKS (OUTPUT PROMPTS) INCLUDING THE RIGHT TO TRAIN AND/OR
                    CREATE OUR ARTIFICIAL INTELLIGENCE/PRODUCTS. UNLESS CLEARLY
                    SPECIFIED, YOUR TASKS WILL BE FOR YOUR OWN USE AND YOU WILL NOT
                    USE THEM FOR COMMERCIAL PURPOSES SUCH AS RESELLING THEM TO
                    OTHERS AND/OR POSTING THEM.
                </div>
                <div className="para">
                    You represent and warrant that: (i) the Contents are yours (you have developed them and /or
                    you own them) or you have the right to use them and grant us the rights and license as
                    provided in these Terms, and (ii) your Contents do not violate the privacy rights, publicity
                    rights, copyrights, contract rights or any other rights of any person.
                </div>
                <div className="para">
                    You must not upload, edit, create, store or share any Contents that violates these Terms. You,
                    furthermore, agree to indemnify, defend and hold Dende harmless for any unauthorized use
                    of third-party user Contents you might commit (both intentionally or unintentionally) in
                    accordance with Section 12 of these Terms.
                </div>
            </div>


            <div className="privacy_text">
                <div className="heading">8. Prohibited Conduct and Content</div>
                <div className="para">
                    <strong>General rules.</strong>  You will not violate any applicable contract, intellectual property law, any
                    other applicable law or other third-party rights, and you are solely responsible for the
                    Contents and for all activity that occur while using Dende. The Company is not responsible
                    for the Contents that you upload. You expressly understand and agree that you are solely
                    responsible for the Contents and for all activity that occurs under your account, whether done
                    so by you or any third person using your account.
                </div>

                <div className="para">
                    You represent, warrant and agree that you will not use Dende by uploading the Contents or
                    otherwise (all of the following conduct includes any attempts to perform any of the
                    following):
                </div>

                <div className="para">
                    ● To engage in any harmful, fraudulent, deceptive, harassing, threatening, intimidating,
                    predatory, defamatory, obscene, stalking or otherwise objectionable conduct including
                    references or commentary about religion, race, sexual orientation, gender, national/ethnic
                    origin, or other targeted groups.<br />
                    ● For sale or to the benefit of any third party or in any manner not permitted by these
                    Terms.<br />
                    ● In any manner that could interfere with, disrupt, negatively affect or inhibit other users
                    from fully enjoying Dende or that could damage, disable, overburden or impair the
                    functioning of Dende in any manner.<br />
                    ● To decipher, decompile, disassemble or reverse engineer any aspect of Dende such as the
                    source code or non-literal aspects (such as the underlying structure, sequence,
                    organization, file formats, non-public APIs, ideas or algorithms), do anything that might

                    discover the source code, bypass or circumvent measures employed to prevent or limit
                    your access to any part of Dende.<br />
                    ● To circumvent any content-filtering techniques we employ or to access any feature or
                    area of Dende that you are not authorized to access.<br />

                    ● To develop or to use any third-party applications that interact with Dende without our
                    prior written consent, including any scripts designed to scrape or extract data from
                    Dende.<br />
                    ● To display, mirror or frame Dende, or any individual element within Dende, the
                    Company’s name, any Company trademark, logo or other proprietary information without
                    our express written consent.<br />
                    ● To use any meta tags or other hidden text or metadata utilizing the Company’s trademark,
                    logo URL, product or services name without our express written consent.<br />
                    ● To gain unauthorized access to, interfere with, damage, or disrupt any server, computer or
                    database connected to Dende.<br />
                    ● To copy, modify, host, stream, sublicense, or resell Dende.<br />
                    ● For any illegal or unauthorized purpose, or engage in, encourage or promote any activity
                    that violates these Terms.<br />
                    ● To Violate the privacy of any third person.<br />
                    ● To place an advertisement of any products or services in Dende except with our prior
                    written approval.<br />
                    ● To violate any applicable law or regulation.<br />
                    ● To encourage, assist or enable any other party to do any of the foregoing.<br />
                    You may only share the Contents that are non-confidential and you have all necessary rights
                    to disclose. You may not upload, edit, create, store or share any Contents that:<br />
                    ● Is unlawful, libelous, defamatory, obscene, pornographic, indecent, lewd, suggestive,
                    harassing, threatening, invasive of privacy or publicity rights, abusive, inflammatory or
                    fraudulent.<br />
                    ● Would constitute, encourage or provide instructions for a criminal offense, violate the
                    rights of any person or otherwise create liability or violate any local, state, national or
                    international law.<br />
                    ● May infringe, misappropriate or violate any patent, trademark, trade secret, copyright or
                    other intellectual or proprietary right of any person.<br />
                    ● Contains any private or personal information of any person without such person’s
                    consent.<br />
                    ● Contains any viruses, corrupted data or other harmful, disruptive or destructive files or
                    content.<br />
                    ● Is, in our sole judgment, objectionable or that restricts or inhibits any other person from
                    Using or enjoying Dende, or that may expose us or others to any harm or liability of any
                    type.<br />
                    We have the right to monitor your use of Dende to ensure compliance with these Terms, and
                    to comply with applicable law or other legal requirements. We have the right to investigate
                    violations of these Terms or conduct that affects Dende. We may also consult and cooperate
                    with law enforcement authorities to prosecute users who violate the applicable law.<br />

                    We reserve the right to, in our discretion and at any time, suspend or discontinue Dende,
                    introduce new features or impose limitations on certain features, or restrict access to Dende.
                    We also reserve the right, but not the obligation, to, in our sole discretion, determine whether
                    or not any Contents are appropriate and complies with this Terms, refuse or remove this
                    Contents. We further reserve the right to make formatting and edits and change the manner of
                    any Contents.<br />

                </div>
            </div>


            <div className="privacy_text">
                <div className="heading">9. Copyright Infringement</div>
                <div className="para">
                    It is our policy to respect the legitimate rights of copyright owners, and we will respond to
                    notices of alleged copyright infringement that comply with applicable law. We also employ
                    an anti-plagiarism software to monitor and prevent copyright protected contents to be
                    illegally uploaded on Dende. If you believe that any text, graphics, photos or other materials
                    or works, including any Contents, uploaded or appearing on Dende have been copied in a
                    way that constitutes copyright infringement, you may request removal of that Content (or
                    access to it) from Dende by submitting a written notification to us at info@dende.ai If we
                    become aware that any of our users have repeatedly infringed copyrights, we will take
                    reasonable steps to disable access to Dende for such users.
                </div>
            </div>
            <div className="privacy_text">
                <div className="heading">10.Intellectual Property</div>
                <div className="para">
                    The Services and their original contents, features and functionality will remain the exclusive
                    property of the Company. According to Section 7, Dende will have the exclusive and royalty
                    free right to use, modify, reproduce, and distribute the Contents for commercial or
                    non-commercial purposes. Any IP derived from the Contents shall remain the exclusive
                    property of the Company. The Services are protected by copyright, trademark, and other
                    European and international laws. Our trademarks and trade dress may not be used in
                    connection with any product or service without the prior written consent of the Company.
                </div>
            </div>
            <div className="privacy_text">
                <div className="heading">11.Feedback</div>
                <div className="para">
                    We welcome any your feedback, questions, comments, suggestions, ideas, original or
                    creative materials or other information you submit about Dende (collectively, “Feedback”).
                    You can submit Feedback by contacting us at info@dende.ai or otherwise, for additional
                    contact details please see Section 21 of these Terms. Your Feedback is non-confidential and
                    will become our sole property once submitted to us. We will own exclusive rights, including,
                    without limitation, all intellectual property rights, in and to your Feedback and will be
                    entitled to the unrestricted use and dissemination of your Feedback for any purpose,
                    commercial or otherwise, without acknowledgment or compensation to you. You also have
                    the right to amend and supplement your Feedback to us, which we will consider to provide
                    accurate information to other users about Dende user experience. If we decide to make your
                    Feedback public, we will not use any of your personal data, unless you explicitly consent to
                    it.
                </div>
            </div>
            <div className="privacy_text">
                <div className="heading">12.Indemnification</div>
                <div className="para">
                    To the fullest extent permitted by applicable law, you will indemnify, defend, and hold
                    harmless us and each of our respective officers, directors, agents, partners and employees
                    (individually and collectively, “Company Parties”) from and against any loss, liability, claim,
                    demand, damages, expenses or costs (“Claims”) arising out of or related to (i) your use of
                    Dende; (ii) your Contents or Feedback; (iii) your violation of these Terms; (iv) your
                    violation, misappropriation or infringement of any rights of another (including intellectual
                    property rights or privacy rights); and (v) your conduct in connection with Dende. You agree
                    to promptly notify the Company Parties of any third-party claims, cooperate with the
                    Company Parties in defending such claims and pay all fees, costs and expenses associated
                    with defending such claims (including, but not limited to, attorneys’ fees). You also agree
                    that the Company Parties will have control of the defense or settlement of any third-party
                    claims. This indemnity is in addition to, and not in lieu of, any other indemnities set forth in a
                    written agreement between you and us or the other Company Parties.
                </div>
            </div>
            <div className="privacy_text">
                <div className="heading">13.Disclaimers</div>
                <div className="para">
                    We do not control, endorse or take responsibility for any Contents (and its loss) or third-party
                    content available on or linked to Dende. You acknowledge sole responsibility for and assume
                    all risk arising from your use of any third-party websites or resources.
                </div>
                <div className="para">
                    Your use of Dende is at your sole risk. Dende is provided “as is” and “as available” without
                    warranties of any kind, either express or implied, including, but not limited to, implied
                    warranties of merchantability, fitness for a particular purpose, title, and non-infringement. In
                    addition, we do not represent or warrant that Dende is accurate, complete, reliable, current,
                    error-free or free of viruses or harmful components. You assume the entire risk as to the
                    quality and performance of Dende within your use.
                </div>
            </div>

            <div className="privacy_text">
                <div className="heading">14.Limitation of Liability</div>
                <div className="para">
                    The Company will not be liable to you under any theory of liability — whether based in
                    contract, tort, negligence, strict liability, warranty, or otherwise — for any indirect,
                    consequential, exemplary, incidental, punitive or special damages or lost profits, even if you
                    have been advised of the possibility of such damages.
                </div>
                <div className="para">
                    The total liability of the Company for any claim arising out of or relating to these Terms,
                    regardless of the form of the action, is limited to the amount paid, if any, by you to use
                    Dende. In no event will the Company’s total liability arising out of or in connection with
                    these Terms or from the use of or inability to use Dende exceed the amounts you have paid to
                    use Dende paid features. The exclusion and limitations of damages set forth above are
                    fundamental elements of the basis of the bargain between the Company and you.
                </div>
                <div className="para">
                    The limitations set forth in this Section will not limit or exclude liability for fraud or
                    intentional misconduct of the Company for any other matters in which liability cannot be
                    excluded or limited under applicable law. Additionally, some jurisdictions do not allow the
                    exclusion or limitation of incidental or consequential damages, so the above limitations or
                    exclusions may not apply to you.
                </div>
            </div>
            <div className="privacy_text">
                <div className="heading">15.Release</div>
                <div className="para">
                    To the fullest extent permitted by applicable law, you release the Company from
                    responsibility, liability, claims, demands, and/or damages (actual and consequential) of every
                    kind and nature, known and unknown (including, but not limited to, claims of negligence),
                    arising out of or related to disputes between the users and the acts or omissions of third
                    parties.
                </div>
            </div>
            <div className="privacy_text">
                <div className="heading">16.Dispute Resolution</div>
                <div className="para">
                    All disputes arising out of or relating to these Terms will be first resolved through contacting
                    the Company by sending an e-mail to the following address: info@dende.ai (subject:
                    "Complaint"), or by letter sent to the registered office of Dende, indicated in Section 21. In
                    case that the customer service complaint request is unsuccessful, the user may submit the
                    dispute between him and the Company to a mediator who will try, in total independence and
                    impartiality, to reconcile the parties to reach a amicable solution.
                </div>
                <div className="para">
                    The European Commission has also created an online platform for resolving disputes ("ODR
                    platform") between businesses and consumers. The ODR platform can be accessed at
                    ec.europa.eu/consumatori/odr/. Dende is free to decide whether or not to participate in a
                    dispute resolution proceeding before the arbitration board of Milan. It is also Dende’s faculty,
                    in the event that it takes part in a mediation procedure, to accept or reject what is proposed.
                    In the absence of an amicable agreement, any dispute relating to the application of these
                    general conditions of sale and their interpretation, their execution will be resolved in the
                    courts of Milan.
                </div>
            </div>
            <div className="privacy_text">
                <div className="heading">17.Governing Law and Venue</div>
                <div className="para">
                    These Terms will be governed by, construed and enforced in accordance with the laws of
                    Italy, without regard to conflict of law rules or principles (whether Italy or any other
                    jurisdiction) that would cause the application of the laws of any other jurisdiction. However,
                    some countries (including those in the European Union) have laws that require agreements to
                    be governed by the local laws of the user's country. This paragraph doesn’t override those
                    laws.
                    Any dispute between you and the Company that is not subject to arbitration will be resolved
                    in the courts of Milan.
                </div>
            </div>


            <div className="privacy_text">
                <div className="heading">18.Right to Refuse Service</div>
                <div className="para">
                    We may close, suspend or limit your access to Dende without reason. Without limiting the
                    foregoing, we may close, suspend or limit your access to Dende:<br />
                    ● if we determine that you have breached, or are acting in breach of these Terms;<br />

                    ● if we determine that you have infringed legal rights (resulting in actual or potential
                    claims), including infringing Intellectual Property Rights;<br />
                    ● if we determine that you have engaged, or are engaging, in fraudulent, or illegal
                    activities;<br />
                    ● you do not respond to account verification requests;<br />
                    ● you do not complete account verification when requested;<br />
                    ● to manage any risk of loss to us, a user, or any other person; or for any other reasons.<br />
                </div>
            </div>
            <div className="privacy_text">
                <div className="heading">19.Changes to These Terms</div>
                <div className="para">
                    We may make changes to these Terms from time to time. If we make changes, we will post
                    the amended Terms and update the “Effective date” above. In case we make substantial
                    changes to the Terms (including the changes which might affect your rights), we will always
                    notify you. If you do not agree to the amended terms, you must stop using Dende. Your
                    continued use of Dende after we provide the notice will imply your acceptance of those
                    changes.
                </div>
            </div>
            <div className="privacy_text">
                <div className="heading">20.Termination</div>
                <div className="para">
                    We reserve the right, without notice and in our sole discretion, to terminate your right to use
                    Dende. We are not responsible for any loss or harm related to your inability to use Dende.
                    Upon any termination, discontinuation or cancellation of Dende, all provisions of these
                    Terms, which by their nature should survive, will survive, including without limitation,
                    ownership provisions, warranty disclaimers, limitations of liability and dispute resolution
                    provisions.
                </div>
            </div>
            <div className="privacy_text">
                <div className="heading">21.Contact us</div>
                <div className="para">
                    General contact details. If you have any questions about this Terms or the Company, please
                    contact us via email at info@dende.ai or our mailing address:
                </div>
            </div>

            <div className="subheading">
                Dende Srl<br />
                Corso Buenos AIres 23<br />
                20124 Milano (MI)<br />
                VAT number: 13234980962<br />
                Italy<br />
            </div>


            <div className="privacy_text">
                <div className="heading">22.Miscellaneous</div>
                <div className="para">
                    ● These Terms constitute the entire agreement between you and us relating to Dende and
                    your use of Dende, and these Terms supersede and replace any and all prior oral or
                    written understandings or agreements between the Company and you relating to Dende
                    and your use of Dende.<br />
                    ● The language of these Terms is the English language only.<br />

                    ● You hereby irrevocably waive any law applicable to you requiring that these Terms shall
                    be localized to meet your language (as well as any other localization requirements) or
                    requiring a delivery or retention of non-electronic records.<br />
                    ● The failure of us to exercise or enforce any right or provision of these Terms will not
                    operate as a waiver of such right or provision. The waiver of such right or provision will
                    be effective only if in writing and signed by a duly authorized representative of the
                    Company.<br />
                    ● Except as expressly set forth in these Terms, the exercise by either party of any of its
                    remedies under these Terms will be without prejudice to its other remedies under these
                    Terms or otherwise.<br />
                    ● If any provision of these Terms is held invalid and unenforceable (either by a mediator,
                    ORD platform or arbitrator appointed pursuant to the terms of the Dispute Resolution;
                    above or by court of competent jurisdiction), that provision will be enforceable to the
                    maximum extent permissible and the other provisions of these Terms will remain in full
                    force and effect. The Section titles in these Terms are for convenience only and have no
                    legal or contractual effect.<br />
                    ● Except as otherwise provided herein, these Terms are intended solely for the benefit of
                    you and the Company and are not intended to confer third party beneficiary rights upon
                    any other person or entity.<br />
                    ● You may not assign or transfer your rights under these Terms, by operation of law or
                    otherwise, without our prior written consent. Any attempt by you to assign or transfer
                    your rights under these Terms, without such consent, will be null and of no effect. We
                    may freely assign or transfer our rights and obligations under these Terms without
                    restriction. Subject to the foregoing, these Terms will bind and inure to the benefit of the
                    parties, their successors and permitted assigns.<br />
                </div>

            </div>

        </div>
    )
}

export default Terms
