import React, { useEffect } from 'react'
import styles from "./Home.module.scss"
import { useNavigate, useLocation } from "react-router-dom";
import dendeLogo from "../../assets/dende-new-logo.png"


const Home = () => {

useEffect(() => {
	if(process.env.REACT_APP_API_ENDPOINT === "https://app.dende.ai/api"){
		// navigate other domain
		window.location.href = "https://dende.ai";
	}
}, [])

	const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
	const navigate = useNavigate();
	return (
		<>
		{
			apiEndpoint === "https://release.dendedev.it/api"
			?
			<>
				<div className={styles["container"]}>
					<div className={styles["content"]}>
						<img src={dendeLogo} alt="dende logo" />
						<div>
							<p
								onClick={(e)=>{navigate("/login")}}
							>
								Login
							</p>
							<p 
								onClick={()=>{navigate("/signup")}}
							>
								Signup
							</p>
						</div>
					</div>
				</div>
			</>
			:
			<></>
		}
		</>
	)
}

export default Home
