import React, { useEffect, useState, useRef, useContext } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  loginRequest,
  handleSSOLoginData,
  getUserMetadata,
  forgetPassword,
  loginNew,
  getApiUrl,
  getUserAuthToken,
  GetUserInfoAPI,
  SendDendeEvents
} from "../../helpers/requests";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import 'react-toastify/dist/ReactToastify.css';
import "./Login.scss";
import { useDispatch, useSelector } from "react-redux";
import { addTimeLog, masterRequest } from "../../helpers/requests";
import GoogleLogo from "../../assets/google-logo.png";
import GoogleAuthBtn from "../../Components/GoogleAuthBtn/GoogleAuthBtn";
import LoaderSpinner from "../../Components/LoaderSpinner/LoaderSpinner";
import { install } from 'ga-gtag';
import { LoginTopBar } from "../../Components/LoginTopBar/LoginTopBar";
import { UAParser } from 'ua-parser-js';
import { CurrentUserInfo } from "../../Components/ContextStore/ContextStore";

const Login = () => {
  const { sharedUserInfo, setSharedUserInfo } = useContext(CurrentUserInfo);
  var apiUrl = getApiUrl();
  var token = getUserAuthToken();
  const email_id_input = useRef(null);
  const dispatch = useDispatch();
  const [eye, seteye] = useState(false);
  const [progress, setProgress] = useState(false);
  const [loader_value, set_loader_value] = useState(false);
  const [reset_pass_popup_visibility, set_reset_pass_popup_visibility] = useState(false);
  const [reset_popup_content, set_reset_popup_content] = useState("reset_password");
  const [loginValues, setLoginValues] = useState({
    email: "",
    password: "",
  });

  const location = useLocation();
  const navigate = useNavigate();

  function handleChange(event) {
    const { name, value } = event.target;
    setLoginValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }

  useEffect(() => {
    if (apiUrl === "https://app.dende.ai/api" || apiUrl === "https://staging.dende.ai/api") {
      install('G-K9ES37HT6F', { debugger: true, 'send_page_view': false });
    } else {
      install('G-GMWDDVX7G6', { debugger: true, 'send_page_view': false });
    }
    var url = window.location.href;
    if (url.includes("activate")) {
      let tempArray1 = url.split("?");
      let tempArray2 = tempArray1[1].split("&")

      if (tempArray2[0] === "activate") {
        toast.success(
          "Account is confirmed, please enter your login details to continue.",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: true,
          }
        );
        let user_id_str = atob(tempArray2[1]);
        let user_id = parseInt(user_id_str)
        // CreateGtagEvent("email_confirmation", {
        //   "user_id": user_id,
        //   "confirmation_type": 'email'
        // });
      }
    } else {
      checkSessionIsValid()
    }
  }, []);

  async function checkSessionIsValid(){
    try {
      const token = localStorage.getItem('token');
      if (token) {
        let apiResponse = await GetUserInfoAPI(token);
        if (apiResponse.status) {
          if(apiResponse.data.length > 0){
            let userMetadataResponse = await getUserMetadata();
            SendDendeEvents(
              {
                "event_type": "user_fast_login",
                "metadata": userMetadataResponse
              }
            )
            navigate('/home')
          }
        }
      }
    } catch (error) {
      console.error('Error accessing local storage:', error);
      return false;
    }
  }


  useEffect(() => {
    if (location.pathname === "/quiz") {
      console.log('chnage in path', location.pathname)
    }
  }, [location.pathname]);

  // useEffect(()=>{
  //   if(reset_pass_popup_visibility === false){
  //     email_id_input.current.value = "";
  //   }
  // }, [reset_pass_popup_visibility])


  function handleMarketingData(login_response) {
    try {
      // google tag manager
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "login",
        user_id: String(login_response?.data?.id) ?? "",
        user_email: login_response?.data?.email ?? "",
      });
    } catch (error) {
      console.error('login page', error);
    }
  }


  const logingin = async () => {
    if (loginValues.email !== "" && loginValues.password !== "") {

      let userMetadataResponse = await getUserMetadata();
      let loginRequestData = {}
      loginRequestData = { ...loginValues }
      loginRequestData["metadata"] = userMetadataResponse

      const loginRes = await loginNew(loginRequestData);
      if (loginRes.status) {

        setSharedUserInfo({})

        localStorage.setItem("firstSignedUp", false);
        toast.success("Login Successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true,
        });
        localStorage.setItem('quiz_hints_guide_text_hover_count', 0);
        localStorage.setItem('quiz_hints_guide_text_show_count', 0);
        handleMarketingData(loginRes)
        if (loginRes.data.role === "user") {
          createIndexedDB()
          if (loginRes.data.resource_count === 0) {
            navigate("/initial-upload");
          } else {
            navigate("/home");
          }
        } else if (loginRes.data.role === "super_admin") {
          navigate("/users");
        }

      } else {
        if (loginRes.message === "Please continue login with google") {
          set_reset_pass_popup_visibility(true);
          set_reset_popup_content("google_sso");
        } else {
          toast.error(loginRes.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: true,
          });
        }
      }
    } else {
      toast.error("Please fill username and password", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
    }
  }


  function closeResetPassPopup(e) {
    if (e.target.className === "reset-password-container") {
      set_reset_pass_popup_visibility(false);
      set_reset_popup_content("reset_password");
    }
  }

  function createIndexedDB() {
    // delete existing DB
    let deleteDB = indexedDB.deleteDatabase("dendeLocalDB");
    deleteDB.onerror = function (event) {
      console.error("Error deleting database.", event.target.errorCode);
    };


    let db;
    let request = indexedDB.open("dendeLocalDB", 1);
    request.onupgradeneeded = function (event) {
      db = event.target.result;
      db.createObjectStore("table1", { keyPath: 'id' });
    };
    request.onerror = function (event) {
      // Log the error code
      toast.error("indexedDB error create db", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
      console.error("Error opening database: " + event.target.errorCode);
    };
  }

  async function handleResetPassword() {
    if (email_id_input.current.value !== "") {
      let request_data = {
        "email": email_id_input.current.value
      }
      let api_response = await forgetPassword(request_data)

      if (api_response.message.toLowerCase() === "reset password link has been sent to your email id") {
        toast.success("Reset password link has been sent to your email id", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true,
        });
        set_reset_popup_content("email_send");
      } else if (api_response.message.toLowerCase() === "email id is not valid") {
        toast.error("email id is not valid", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true,
        });
      } else {
        toast.error("can't reset password", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true,
        });
      }
    } else {
      toast.error("please enter your email id", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
    }
  }

  return (
    <>
      <LoginTopBar />
      <ToastContainer />
      <div className="main_login">
        <h2 className="login_title">Log in</h2>
        <div className="login_input">
          <div className="login-textinput-container">
            <span style={loginValues.email === "" ? { display: "none" } : { display: "block" }}>Your Email</span>
            <input
              onChange={handleChange}
              className="custom-input"
              type={"text"}
              placeholder="Your Email"
              name="email"
              value={loginValues.email}
            />
          </div>
          <div className="main_pass">
            <div className="login-textinput-container">
              <span style={loginValues.password === "" ? { display: "none" } : { display: "block" }}>Password</span>
              <input
                onChange={handleChange}
                className="custom-input"
                type={eye ? "text" : "password"}
                placeholder="Password"
                name="password"
                value={loginValues.password}
              />
            </div>
            <div className="eye" onClick={() => seteye(!eye)}>
              {eye ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </div>
          </div>
          <div onClick={() => {
            set_reset_pass_popup_visibility(true);
            set_reset_popup_content("reset_password");
            email_id_input.current.value = "";
          }} className="forget-password">
            forgot your password?
          </div>
        </div>
        {/* <Link style={{ textDecoration: 'none' }} to='/aloha' >
      </Link> */}
        <button id="login" className="button login-btn" onClick={() => { logingin(); }}>
          Login
        </button>

        <div className="login-google-sso-container">
          <p className="login-google-sso-divider">
            <span>OR</span>
          </p>
          <div className="login-google-sso-text">Log in with:</div>
          <div className="login-google-sso-button">
            {/* <button onClick={()=>{googleBtnClick()}}>
                <img src={GoogleLogo} alt="" />
                Google
              </button> */}
            <GoogleAuthBtn />
          </div>
        </div>
      </div>
      <></>
      <LoaderSpinner data={loader_value}></LoaderSpinner>
      <section
        onClick={(e) => { closeResetPassPopup(e) }}
        style={reset_pass_popup_visibility ? { display: "flex" } : { display: "none" }}
        className="reset-password-container">
        <div className="reset-password-content">
          <h2 style={reset_popup_content === "reset_password" || reset_popup_content === "email_send" ? { display: "block" } : { display: "none" }}>Password reset</h2>
          <div style={reset_popup_content === "google_sso" ? { display: "block" } : { display: "none" }}>
            <h2
              className="google-sso-popup-h1 google-sso-popup-h1-margin"
            >
              Login with Google account
            </h2>
            <p
              style={{
                fontSize: "15px",
                marginTop: "20px"
              }}
            >
              Welcome back! It seems that you originally signed in with your Google account. Please use the button below to login.
            </p>
          </div>
          <div className="google-sso-popup" style={reset_popup_content === "google_sso" ? { display: "flex" } : { display: "none" }}>
            <GoogleAuthBtn />
          </div>
          {reset_popup_content === "reset_password" ? (
            <>
              <input placeholder="Your email" ref={email_id_input} type="email" />
              <button id='reset_password' onClick={() => { handleResetPassword() }}>Reset password</button>
              <p>Already have an account?
                <Link onClick={() => {
                  set_reset_popup_content("reset_password");
                  set_reset_pass_popup_visibility(false);
                }}>
                  <span>{" "}Login</span>
                </Link>
              </p>
            </>
          ) :
            reset_popup_content === "email_send" ?
              (
                <>
                  <h3>Password reset link send to the register email</h3>
                  <button onClick={() => {
                    set_reset_popup_content("reset_password");
                    set_reset_pass_popup_visibility(false);
                  }}>
                    Return to Login
                  </button>
                </>

              ) : null}
        </div>
      </section>
    </>
  );
};

export default Login;
