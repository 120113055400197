import React, { useState, useRef, useEffect, useContext } from "react";
import style from "./Reread.module.scss";
import {
  GetUserInfoAPI,
  updateUserProfile,
  getApiUrl,
  getUserAuthToken,
  SendDendeEvents
} from "../../helpers/requests";
import { ToastContainer, toast } from "react-toastify";
import dendeNewLogo from "../../assets/dende-new-logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SpecialZoomLevel, Viewer } from "@react-pdf-viewer/core";
import { Page } from "@react-pdf-viewer/core";
import customZoomPlugin from "../../Components/PdfViewerContols/CustomZoomPlugin";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";
import LoadingGif from "../../assets/loading-svg.svg";
import Reread_empty from "../../assets/reread_icon_empty_state.png";
import { CurrentUserInfo } from "../../Components/ContextStore/ContextStore";

//For Zoom
import {
  RenderCurrentScaleProps,
  RenderZoomInProps,
  RenderZoomOutProps,
  zoomPlugin,
} from "@react-pdf-viewer/zoom";

// Import styles
import "@react-pdf-viewer/zoom/lib/styles/index.css";

//For navigation
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";

import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronUpIcon,
  MinusCircleIcon,
  MinusIcon,
  PlusCircleIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";

export default function Reread() {
  const { sharedUserInfo, setSharedUserInfo } = useContext(CurrentUserInfo);
  const global_default_examspace_id = useRef("");  
  var token = getUserAuthToken();
  var apiUrl = getApiUrl();
  const navigate = useNavigate();

  //pdf section
  const [getpdf, setpdf] = useState(null);
  const quesSection = useRef();
  const pdfSection = useRef();
  const pdfSectionTouch = useRef();
  const [zoomvalue, setZoomValue] = useState(1);
  const zoomValue = useRef(1);
  const [loadingContent, setLoadingContent] = useState(true);
  const currentSelectedQuestion = useRef(null);

  // PDF Zoom
  const zoomPluginInstance = zoomPlugin();

  // State to store the initial distance between touches
  const [initialDistance, setInitialDistance] = useState(null);

  // ZoomInButton,
  // ZoomOutButton,
  // ZoomPopover,
  // CurrentScale,
  // ZoomIn,
  // ZoomOut,
  // Zoom,
  const { CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance;

  const customZoomPluginInstance = customZoomPlugin();
  const { zoomTo } = customZoomPluginInstance;

  // PDF Navigation
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const {
    CurrentPageInput,
    GoToFirstPageButton,
    GoToLastPageButton,
    GoToNextPageButton,
    GoToPreviousPage,
    CurrentPageLabel,
    GoToNextPage,
    GoToFirstPage,
  } = pageNavigationPluginInstance;

  //data for reread
  const [get_all_question, set_get_all_question] = useState([]);
  const [limited_question_array, set_limited_question_array] = useState([]);

  //pdf page number
  const [pagenumber, setPageNumber] = useState(0);

  //pdf zoom dropdown
  const [zoommenu, setZoomMenu] = useState(false);

  // for pagination
  const [totalpages, setTotalPages] = useState(0);

  const [clickreread, setClickReread] = useState(false);



  // ============== IndexedDB Utility Functions ================
  
function indexeddbAddData(inputData){
  let db;
  let request = indexedDB.open("dendeLocalDB", 1);
  request.onsuccess = function(event) {
    db = event.target.result;
    let transaction = db.transaction(["table1"], "readwrite");
    let table1 = transaction.objectStore("table1");
    let response1 =  table1.add({id: 100, data: inputData});
    response1.onsuccess = function(event) {
    };
    response1.onerror = function(event) {
      toast.error("indexedDB error add data", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
      console.error("Error adding data: " + event.target.errorCode);
    };
  };
  request.onerror = function(event) {
    toast.error("indexedDB error", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: true,
    });
    console.error("indexedDB Error" + event.target.errorCode);
  };
}


function indexeddbGetData() {
  return new Promise( (resolve, reject) => {
    let db;
    let output = undefined;
    let request = indexedDB.open("dendeLocalDB", 1);
    request.onsuccess = function(event) {
      db = event.target.result;
      let transaction = db.transaction(["table1"], "readwrite");
      let table1 = transaction.objectStore("table1");
      let response1 =  table1.get(100);

      response1.onsuccess = function(event) {
        resolve(event.target.result);
      };
      response1.onerror = function(event) {
        toast.error("indexedDB error get data", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true,
        });
      };
    };
    request.onerror = function(event) {
      toast.error("indexedDB error", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
    };
  });
}


function indexeddbUpdateData(inputData) {
  let db;
  let request = indexedDB.open("dendeLocalDB", 1);
  request.onsuccess = function(event) {
    db = event.target.result;
    let transaction = db.transaction(["table1"], "readwrite");
    let table1 = transaction.objectStore("table1");
    let response1 =  table1.put({id: 100, data: inputData});

    
    response1.onerror = function(event) {
      toast.error("indexedDB error update data", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
      console.error("indexedDB error update data : " + event.target.errorCode);
    };
  };
  
  request.onerror = function(event) {
    toast.error("indexedDB error update data", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: true,
    });
    console.error("indexedDB error: " + event.target.errorCode);
  };
}


  //=========== CALL API FOR GET REREAD DATA ============
  useEffect(() => {
    proxyCallGetDefaultExamspaceIdUtility();
  }, []);

  async function proxyCallGetDefaultExamspaceIdUtility(){
    let examspace_id = await getDefaultExamspaceIdUtility();
    getRereadData();
  }

  function getRereadData() {
    let default_examspace_id = localStorage.getItem('default_examspace_id')
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    fetch(`${apiUrl}/reread?default_examspace_id=${global_default_examspace_id.current}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setLoadingContent(false);
        if (response.status) {
          // handlePagination(response.data);
          set_get_all_question(response.data);
          if (response.data.length) {
            setTotalPages(Math.ceil(response.data.length / 10));
            // handleClickReread(response.data[1]);
            setPageNumber(response.data[0].matching_page - 1);
          }
        }
      });
  }

  //========= CLICK REREAD BUTTON ===============
  function handleClickRereadShowPdf(from) {
    if (window.innerWidth < 600) {
      let pdfContainerQuiz =
        document.getElementsByClassName("pdf-container-quiz");
      if (pdfContainerQuiz.length) {
        pdfContainerQuiz[0].style.transform = "translateX(0)";
        pdfContainerQuiz[0].style.visibility = "visible";
      }
    }
    // let getToolbarContainer = document.getElementById("topbar-container");
    // let getReadContainer = document.getElementById("rereadContainer");

    // if (from === "question") {
    //   //hide header when user read pdf
    //   if (getToolbarContainer && window.innerWidth < 600) {
    //     getToolbarContainer.style.display = "none";
    //     getToolbarContainer.style.padding = "0px";
    //   }
    //   if (getReadContainer && window.innerWidth <= 600) {
    //     getReadContainer.style.margin = "0px";
    //   }

    //   if (window.innerWidth <= 1000) {
    //     quesSection.current.style.display = "none";
    //   } else {
    //     quesSection.current.style.display = "block";
    //   }

    //   if (pdfSection) {
    //     pdfSection.current.style.transform = "translateX(0)";
    //     pdfSection.current.style.visibility = "visible";
    //   }
    // } else if (from === "pdf") {
    //   //show header when user read pdf
    //   if (pdfSection) {
    //     pdfSection.current.style.transform = "translateX(100%)";
    //     setTimeout(() => {
    //       pdfSection.current.style.visibility = "hidden";
    //     }, 600);
    //   }

    //   setTimeout(() => {
    //     if (getToolbarContainer) {
    //       getToolbarContainer.style.padding = "20px 20px";
    //       getToolbarContainer.style.display = "block";
    //     }

    //     if (getReadContainer) {
    //       getReadContainer.style.margin = "5px 30px";
    //     }

    //     if (window.innerWidth <= 1000) {
    //       quesSection.current.style.display = "block";
    //     }
    //   }, 400);
    // }
  }

async function handleClickReread(data) {
    setClickReread(true)
    setpdf(null);

    // read blob data present in the indexedDB
    // if present in indexedDB load from local data
    let doesLocalBlobPresent = false;
    let localBlobData;
    let tempFileName1 = data.resource_url.split("/");
    let tempFileName2 = tempFileName1[tempFileName1.length - 2];
    let blobArray = await indexeddbGetData();
    if(blobArray){
      let tempArray = blobArray.data;
      let foundItem = tempArray.filter(item => item["resourceId"] === tempFileName2);
      if(foundItem.length > 0){
        doesLocalBlobPresent = true;
        localBlobData = foundItem[0]["fileBlob"];
      }
    }
    if(doesLocalBlobPresent){
      setClickReread(false)
      setpdf(URL.createObjectURL(localBlobData));
      setPageNumber(data.matching_page - 1);
    } else {
      const body = { resource_uri: data.resource_url };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(body),
      };
      fetch(`${apiUrl}/reread-pdf`, requestOptions)
      .then((response) => response.blob())
      .then( async (response) => {
        let tempRes = response;

          setClickReread(false)
          setpdf(URL.createObjectURL(tempRes));
          setPageNumber(data.matching_page - 1);
          // store blob data to indexedDB
          let tempFileName1 = data.resource_url.split("/");
          let tempFileName2 = tempFileName1[tempFileName1.length - 2]
          let blobArray = await indexeddbGetData();
          if(blobArray){
            let tempArray = blobArray.data;
            tempArray.push({"resourceId": tempFileName2, "fileBlob": tempRes});
            indexeddbUpdateData(tempArray);
          } else {
            let tempArray = [];
            tempArray.push({"resourceId": tempFileName2, "fileBlob": tempRes});
            indexeddbAddData(tempArray);
          }
        });
    }

  }


  // store blob to local storage
  function storeBlobToLocalStorage(blobData, filename){
    let localSavedBlobArray = localStorage.getItem('pdfBlobArray');
    if(localSavedBlobArray){
      localSavedBlobArray = JSON.parse(localSavedBlobArray);
    } else {
      localSavedBlobArray = [];
    }

    const readerObj = new FileReader();
    readerObj.readAsDataURL(blobData);
    readerObj.onloadend = function () {
      const base64data = readerObj.result;
      let tempFileName1 = filename.split("/");
      let tempFileName2 = tempFileName1[tempFileName1.length - 1]
      let blobItem = {
        "fileName": tempFileName2,
        "fileBlob": base64data
      }
      localSavedBlobArray.push(blobItem);
      // save data to local storage
      localStorage.setItem('pdfBlobArray', JSON.stringify(localSavedBlobArray));
    }
  }

  //========== FOR PAGINATION START =================

  // State to keep track of the current page
  const [currentPage, setCurrentPage] = useState(1);

  // Function to handle going to the next page
  const nextPage = () => {
    console.log('next page clicked');
    navigate("/reread");
    if (currentPage < totalpages) {
      setCurrentPage(currentPage + 1);
      setTimeout(() => {
        scrollToElement(null);
      }, 700);
    }
  };

  // Function to handle going to the previous page
  const prevPage = () => {
    console.log('prev page clicked');
    navigate("/reread");
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setTimeout(() => {
        scrollToElement(null);
      }, 700);
    }
  };

  useEffect(() => {
    // Calculate the total number of pages
    const pageSize = 10;

    // Calculate the start and end indices for the current page
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, get_all_question.length);

    // Get the data for the current page
    const currentPageData = get_all_question.slice(startIndex, endIndex);
    set_limited_question_array(currentPageData);
  }, [currentPage, get_all_question]);

  useEffect(() => {
    if (limited_question_array.length) {
      // handleClickReread(limited_question_array[0]);
    }
  }, [limited_question_array]);

  //========== FOR PAGINATION END=================

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("focus") !== null && urlParams.get("page") !== null) {
      setTimeout(() => {
        setCurrentPage(parseInt(urlParams.get("page")));
        scrollToElement(`question_${urlParams.get("focus")}`);
      }, 900);
    }
  }, [limited_question_array]);

  // Function to scroll to a specific element
  function scrollToElement(elementId) {
    let element = document.getElementById(elementId);
    if (element) {
      element.focus(); // Set focus to the element
      element.scrollIntoView({ behavior: "smooth", block: "center" }); // Scroll to the element
    } else {
      let getElement = document.getElementsByClassName("question_1");
      if (getElement && getElement.length) {
        getElement[0].focus();
        getElement[0].scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
    // setTimeout(() => {
    //   navigate("/reread");
    // }, 1000);
  }

  function handleClosePdf() {
    let pdfContainerQuiz =
      document.getElementsByClassName("pdf-container-quiz");
    if (pdfContainerQuiz.length) {
      pdfContainerQuiz[0].style.transform = "translateX(100%)";
      setTimeout(() => {
        pdfContainerQuiz[0].style.visibility = "hidden";
      }, 600);
    }
  }

  async function getDefaultExamspaceIdUtility(){
    return new Promise( async(resolve, reject) => {
      let default_examspace_id = "";
      if( Object.keys(sharedUserInfo).length > 0){
        default_examspace_id = sharedUserInfo.default_examspace_id;
        global_default_examspace_id.current = sharedUserInfo.default_examspace_id;
        resolve(default_examspace_id);
      } 
      else {
        let getResponse = await GetUserInfoAPI()
        if (getResponse.status) {
          if(getResponse.data.length > 0){
            if(Object.keys(getResponse.data[0]).length > 0){
              setSharedUserInfo(getResponse.data[0]);
              default_examspace_id = getResponse.data[0]["default_examspace_id"];
              global_default_examspace_id.current = getResponse.data[0]["default_examspace_id"];
              resolve(default_examspace_id);
            }
          }
        }
      }
      
    });
  }  

  function sendDendeEventsUtility({eventType, documentId, QuestionId}){
    if(eventType === "reread_requested"){
      SendDendeEvents({
        "event_type": "reread_requested",
        "document_id": documentId,
        "question_id": QuestionId,
      });
    } else if(eventType === "reread_completed"){
      SendDendeEvents({
        "event_type": "reread_completed",
        "document_id": documentId,
        "question_id": QuestionId,
      });
    }
  }  

  function checkIfAnotherQuestionIsSelected(question_item){
    if(currentSelectedQuestion.current){
      if(currentSelectedQuestion.current.question_id !== question_item.question_id){
        // old question
        sendDendeEventsUtility({
          eventType: "reread_completed",
          documentId: currentSelectedQuestion.current.resource_id,
          QuestionId: currentSelectedQuestion.current.question_id
        });
        // new question
        sendDendeEventsUtility({
          eventType: "reread_requested",
          documentId: question_item.resource_id,
          QuestionId: question_item.question_id
        });
        currentSelectedQuestion.current = question_item;
      }
    } else {
      sendDendeEventsUtility({
        eventType: "reread_requested",
        documentId: question_item.resource_id,
        QuestionId: question_item.question_id
      });
      currentSelectedQuestion.current = question_item;
    }
  }


  return (
    <>
      <ToastContainer />
      <main id="rereadContainer" className={style["container"]}>
        <section ref={quesSection} className={style["question-section"]}>
          {!loadingContent ? (
            <div className={style["title-section"]}>
              {limited_question_array.length <= 0 ? (
                <>
                  <div
                    style={{ fontSize: "25px" }}
                    className={style["title-content"]}
                  >
                    All good! Your wrong book is clear 👏
                  </div>
                </>
              ) : (
                <div className={style["title-content"]}>
                  you have{" "}
                  <span> {get_all_question.length} wrong questions</span> to
                  clear up
                </div>
              )}
            </div>
          ) : (
            ""
          )}

          {loadingContent ? (
            <div
              style={{ height: "80vh" }}
              className={style["loading-gif-content-container"]}
            >
              <div className={style["loading-gif-content"]}>
                <img src={LoadingGif} alt="loading gif" />
                <p></p>
              </div>
            </div>
          ) : (
            <>
              {limited_question_array.length <= 0 ? (
                <div className={style["question-list"]}>
                  <article className={style["question-card-empty"]}>
                    <p
                      style={{
                        textAlign: "center",
                        paddingBottom: "15px",
                      }}
                    >
                      Keep practicing to find tricky questions and come back
                      here to clear them up
                    </p>
                    <div
                      style={{
                        width: "100%",
                        borderTop: "1px solid #d8d8d8",
                        padding: "10px ",
                      }}
                      className={""}
                    >
                      <Link to={"/quiz"} style={{ textDecoration: "none" }}>
                        <p
                          style={{
                            textAlign: "center",
                            fontWeight: 600,
                            paddingTop: "5px",
                          }}
                          id="reread_page_go_to_quiz"
                        >
                          Go to quiz
                        </p>
                      </Link>
                    </div>
                  </article>
                </div>
              ) : (
                <div className={style["question-list"]}>
                  {limited_question_array.length ? (
                    limited_question_array.map((question_item, index) => {
                      return (
                        <>
                          <article
                            id={`question_${question_item.question_id}`}
                            className={`${style["question-card"]} question_${index}`}
                          >
                            <p className={style["question-text"]}>
                              {question_item.question}
                            </p>
                            <div className={style["answer-text"]}>
                              <h6>Your last answer:</h6>
                              <p>{question_item.answer}</p>
                            </div>
                            <div className={style["question-card-btns"]}>
                              <div
                                className={style["question-btn-box"]}
                                style={{ borderRight: "1px solid #d8d8d8" }}
                                onClick={() => {
                                  handleClickRereadShowPdf("question");
                                  handleClickReread(question_item);
                                  checkIfAnotherQuestionIsSelected(question_item)
                                }}
                                id="reread_page_reread_button"
                              >
                                <button>Reread</button>
                              </div>
                              <div className={style["question-btn-box"]}
                                id="reread_page_try_again_button"
                                onClick={()=>{
                                  navigate(`/quiz?questionId=${question_item.question_id}&redirect=reread&page=${currentPage}`)
                                }}
                              >
                                <button>Try again</button>
                              </div>
                            </div>
                          </article>
                        </>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </>
          )}

          <section
            style={{ display: totalpages <= "1" ? "none" : "block" }}
            className={style["pagination-container"]}
          >
            <div className={style["pagination-wrapper"]}>
              <div className={style["pagination-content"]}>
                <div
                  style={{ opacity: currentPage <= 1 ? -1 : 1 }}
                  onClick={() => prevPage("previous")}
                  className={style["pagination-back"]}
                >
                  {"< "}Back
                </div>
                <div className={style["pagination-number"]}>
                  {currentPage}/{totalpages}
                </div>
                <div
                  style={{ opacity: totalpages === currentPage ? -1 : 1 }}
                  onClick={() => nextPage("next")}
                  className={style["pagination-next"]}
                >
                  Next{" >"}
                </div>
              </div>
            </div>
          </section>
        </section>
        {/* PDF FOR DESKTOP */}
        <section
          tabIndex={0}
          id="pdf-section"
          style={{ display: limited_question_array.length <= 0 ? "none" : "" }}
          ref={pdfSection}
          className={`${style["pdf-section"]}`}
        >
          <div
            // onClick={() => handleClickRereadShowPdf("pdf")}
            className={style["back-to-question-section"]}
          >
            <ChevronLeftIcon className={style["icon-left"]} />
            <p className={style["back-to-question"]}>back to questions </p>
          </div>

          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            {" "}
          </Worker>

          {getpdf ? (
            <div
              className="rpv-core__viewer"
              style={{
                // border: "1px solid rgba(0, 0, 0, 0.3)",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                borderRadius: "5px",
                overflow: "auto",
                backgroundColor: "white",
              }}
            >
              <div className={style["pdf_tools_container"]}>
                {/* For navigations */}
                <div
                  style={{
                    alignItems: "center",
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "center",
                    padding: "4px",
                    gap: "5px",
                  }}
                >
                  <div>
                    <GoToPreviousPage>
                      {(RenderGoToPageProps) => (
                        <button
                          style={{
                            backgroundColor: "white",
                            border: "none",
                            color: "#ffffff",
                            cursor: RenderGoToPageProps.isDisabled
                              ? "not-allowed"
                              : "pointer",
                            paddingTop: "3px",
                          }}
                          disabled={RenderGoToPageProps.isDisabled}
                          onClick={()=>{
                            RenderGoToPageProps.onClick();
                            sendDendeEventsUtility({
                              eventType: "reread_completed",
                              documentId: currentSelectedQuestion.current.resource_id,
                              QuestionId: currentSelectedQuestion.current.question_id
                            });    
                          }}
                        >
                          <ChevronUpIcon
                            style={{ width: "18px", height: "18px" }}
                          />
                        </button>
                      )}
                    </GoToPreviousPage>
                  </div>
                  <div>
                    <GoToNextPage>
                      {(RenderGoToPageProps) => (
                        <button
                          style={{
                            background: "white",
                            // color: RenderGoToPageProps.isDisabled
                            //   ? "#96ccff"
                            //   : "#357edd",
                            border: "none",
                            // color: "#ffffff",
                            cursor: RenderGoToPageProps.isDisabled
                              ? "not-allowed"
                              : "pointer",
                            paddingTop: "3px",
                          }}
                          disabled={RenderGoToPageProps.isDisabled}
                          onClick={()=>{
                            RenderGoToPageProps.onClick();
                            sendDendeEventsUtility({
                              eventType: "reread_completed",
                              documentId: currentSelectedQuestion.current.resource_id,
                              QuestionId: currentSelectedQuestion.current.question_id
                            });
                          }}
                        >
                          <ChevronDownIcon
                            style={{ width: "18px", height: "18px" }}
                          />
                        </button>
                      )}
                    </GoToNextPage>
                  </div>

                  <div style={{ padding: "0px 2px" }}>
                    <CurrentPageInput />
                  </div>

                  {/* ${RenderCurrentPageLabelProps.currentPage + 1} */}
                  <CurrentPageLabel>
                    {(RenderCurrentPageLabelProps) => (
                      <span>{`
                     of ${RenderCurrentPageLabelProps.numberOfPages}`}</span>
                    )}
                  </CurrentPageLabel>
                </div>

                {/* For zoom */}
                <div
                  style={{
                    alignItems: "center",
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "center",
                    padding: "4px",
                    gap: "5px",
                  }}
                >
                  <ZoomOut>
                    {(RenderZoomOutProps) => (
                      <button
                        style={{ backgroundColor: "white" }}
                        onClick={RenderZoomOutProps.onClick}
                      >
                        <MinusCircleIcon
                          style={{
                            width: "22px",
                            height: "22px",
                            stroke: "#737373",
                          }}
                        />
                      </button>
                    )}
                  </ZoomOut>
                  <ZoomIn>
                    {(RenderZoomInProps) => (
                      <button
                        style={{ backgroundColor: "white" }}
                        onClick={RenderZoomInProps.onClick}
                      >
                        <PlusCircleIcon
                          style={{
                            width: "22px",
                            height: "22px",
                            stroke: "#737373",
                          }}
                        />
                      </button>
                    )}
                  </ZoomIn>
                </div>
              </div>

              <div
                ref={pdfSectionTouch}
                style={{
                  flex: 2,
                  overflow: "hidden",
                }}
              >
                <Viewer
                  initialPage={pagenumber}
                  fileUrl={getpdf}
                  plugins={[
                    customZoomPluginInstance,
                    pageNavigationPluginInstance,
                    zoomPluginInstance,
                  ]}
                />
                {/* <Page pageNumber={3} /> */}
              </div>
            </div>
          ) : (
            <div className={style["loading-gif-content"]}>
              {clickreread ? (
                <img src={LoadingGif} alt="loading gif" />
              ) : (
                <div className={style["reread-empty-state"]}>
                  <img src={Reread_empty} alt="reread_empty" />
                  <p className={style["reread_empty_heading"]}>
                    Check the questions you got wrong
                  </p>
                  <p className={style["reread_empty_para"]}>
                    Click “Reread”to open your document <br /> and find out the
                    correct answer
                  </p>
                </div>
              )}
            </div>
          )}
        </section>

        {/* PDF FOR MOBILE */}
        {window.innerWidth < 600 ? (
          <div className="pdf-container-quiz">
            {getpdf ? (
              <div
                className="rpv-core__viewer"
                style={{
                  // border: "1px solid rgba(0, 0, 0, 0.3)",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  borderRadius: "5px",
                  overflow: "auto",
                  backgroundColor: "white",
                  minHeight: "100vh",
                }}
              >
                <div className={"pdf_tools_container"}>
                  {/* For back */}
                  <div
                    onClick={() => handleClosePdf()}
                    className="pdf_tools_container_close"
                  >
                    <ChevronLeftIcon className={"icon-left"} />
                    <p className={style["back-to-question"]}>
                      back to questions{" "}
                    </p>
                  </div>
                  <div className="pdf_tools_container-menu">
                    {/* For navigations */}
                    <div
                      style={{
                        alignItems: "center",
                        backgroundColor: "white",
                        display: "flex",
                        justifyContent: "center",
                        padding: "4px",
                        gap: "5px",
                      }}
                    >
                      <div>
                        <GoToPreviousPage>
                          {(RenderGoToPageProps) => (
                            <button
                              style={{
                                backgroundColor: "white",
                                border: "none",
                                color: "#ffffff",
                                cursor: RenderGoToPageProps.isDisabled
                                  ? "not-allowed"
                                  : "pointer",
                                paddingTop: "3px",
                              }}
                              disabled={RenderGoToPageProps.isDisabled}
                              onClick={RenderGoToPageProps.onClick}
                            >
                              <ChevronUpIcon
                                style={{ width: "18px", height: "18px" }}
                              />
                            </button>
                          )}
                        </GoToPreviousPage>
                      </div>
                      <div>
                        <GoToNextPage>
                          {(RenderGoToPageProps) => (
                            <button
                              style={{
                                background: "white",
                                // color: RenderGoToPageProps.isDisabled
                                //   ? "#96ccff"
                                //   : "#357edd",
                                border: "none",
                                // color: "#ffffff",
                                cursor: RenderGoToPageProps.isDisabled
                                  ? "not-allowed"
                                  : "pointer",
                                paddingTop: "3px",
                              }}
                              disabled={RenderGoToPageProps.isDisabled}
                              onClick={RenderGoToPageProps.onClick}
                            >
                              <ChevronDownIcon
                                style={{ width: "18px", height: "18px" }}
                              />
                            </button>
                          )}
                        </GoToNextPage>
                      </div>

                      <div style={{ padding: "0px 2px" }}>
                        <CurrentPageInput />
                      </div>

                      {/* ${RenderCurrentPageLabelProps.currentPage + 1} */}
                      <CurrentPageLabel>
                        {(RenderCurrentPageLabelProps) => (
                          <span>{`
                     of ${RenderCurrentPageLabelProps.numberOfPages}`}</span>
                        )}
                      </CurrentPageLabel>
                    </div>

                    {/* For zoom */}
                    <div
                      style={{
                        alignItems: "center",
                        backgroundColor: "white",
                        display: "flex",
                        justifyContent: "center",
                        padding: "4px",
                        gap: "5px",
                      }}
                    >
                      <ZoomOut>
                        {(RenderZoomOutProps) => (
                          <button
                            style={{ backgroundColor: "white" }}
                            onClick={RenderZoomOutProps.onClick}
                          >
                            <MinusCircleIcon
                              style={{
                                width: "22px",
                                height: "22px",
                                stroke: "#737373",
                              }}
                            />
                          </button>
                        )}
                      </ZoomOut>
                      <ZoomIn>
                        {(RenderZoomInProps) => (
                          <button
                            style={{ backgroundColor: "white" }}
                            onClick={RenderZoomInProps.onClick}
                          >
                            <PlusCircleIcon
                              style={{
                                width: "22px",
                                height: "22px",
                                stroke: "#737373",
                              }}
                            />
                          </button>
                        )}
                      </ZoomIn>
                    </div>
                  </div>
                  <div></div>
                </div>

                <div
                  style={{
                    flex: 2,
                    overflow: "hidden",
                  }}
                >
                  <Viewer
                    initialPage={pagenumber}
                    fileUrl={getpdf}
                    plugins={[pageNavigationPluginInstance, zoomPluginInstance]}
                  />
                </div>
              </div>
            ) : (
              <div style={{ height: "100%", backgroundColor: "white" }}>
                <div className={style["loading-gif-content"]}>
                  <img src={LoadingGif} alt="loading gif" />
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </main>
    </>
  );
}
