import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { MdOutlineNearbyError } from "react-icons/md";
import { FiTrash2, FiUpload } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import { BiLink } from "react-icons/bi";
import { FaRegEdit } from "react-icons/fa";
import { Empty } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import style from "./Uploadfile.module.scss"
import styleExamSpace from '../../Components/ExamSpacePopup/ExamSpacePopup.module.css'
import plusIcon from "../../assets/plus_orange.png"
import dustbinIcon from "../../assets/dustbin.png"
import blackDot from "../../assets/small-black-dot.png"
import LoadingGif from "../../assets/loading-svg.svg"
import {
  uploadFile,
  masterRequest,
  deleteContentById,
  generate,
  generateFirst,
  GetUserInfoAPI,
  DeleteResource,
  listAllUnskippedResources,
  getAllExamSpaces
} from "../../helpers/requests";

import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { getUserAuthToken, getApiUrl } from "../../helpers/requests";
import { CiMaximize1 } from "react-icons/ci";
import { BiImport } from "react-icons/bi";
import { AiOutlinePlus } from "react-icons/ai";
import ExamSpacePopup from "../../Components/ExamSpacePopup/ExamSpacePopup";
import { TrashIcon, XMarkIcon } from '@heroicons/react/24/solid'
import plus_orange from '../../assets/plus_orange.png'
import { VscNewFile } from "react-icons/vsc";
import { LuFolderInput } from "react-icons/lu";
import { MdOutlineDriveFolderUpload } from "react-icons/md";
import { CurrentUserInfo } from "../../Components/ContextStore/ContextStore";



const Uploadfile = () => {
  const { sharedUserInfo, setSharedUserInfo } = useContext(CurrentUserInfo);
  const global_default_examspace_id = useRef("");
  const delay = 1500;
  const [userPlanType, setUserPlanType] = useState("paid");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const files = useSelector((state) => state.master.contents.file);
  var token = getUserAuthToken();
  var apiUrl = getApiUrl();
  const fileInputElement = useRef(null)
  var current_domain = window.location.origin;
  var upload_file_error_text = `<p>You can have only 1 document at a time in free mode.</p> <a href="${current_domain}/pushmode"><p style="color: #f18f01;">Go push mode to go unlimited</p></a>`;
  var delete_file_error_text = `<p>You will not be able to upload any other document as you reach your limit of 3 for the free mode.</p> <a href="${current_domain}/pushmode"><p style="color: #f18f01;">Go push mode to go unlimited</p></a>`;
  const [showallfiles, setShowAllFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [userSelectedFile, setUserSelectedFile] = useState(null);
  const selectedFilesArray = useRef([]);
  const uploadedFileInfo = useRef(null);
  const innerProgressBar = useRef(null);
  const [showExamspace, setShowExamSpace] = useState(false)
  const [showAddFileOption, setShowAddFileOption] = useState(false)

  const [ExamSpacesList, setExamSpacesList] = useState([]);
  const [selectedExamSpace, setSelectedExamSpace] = useState({});
  const [window_width, set_window_width] = useState(window.innerWidth);

  const allowedFileTypes = [
    "application/pdf",
    "application/vnd.oasis.opendocument.text", // .odt
    "application/rtf", // .rtf
    "text/markdown", // .md
    "text/plain", // .txt
    "application/msword", // .doc
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
    "application/vnd.ms-powerpoint", // .ppt
    "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
    "application/vnd.oasis.opendocument.presentation" // .odp
  ];  

  useEffect(() => {
    proxyCallGetDefaultExamspaceIdUtility();
    const handleResize = () => {
      set_window_width(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    proxyCallGetDefaultExamspaceIdUtility2()
  }, [sharedUserInfo]);


  async function proxyCallGetDefaultExamspaceIdUtility2(){
    let examspace_id = await getDefaultExamspaceIdUtility();
  }


  async function proxyCallGetDefaultExamspaceIdUtility(){
    let examspace_id = await getDefaultExamspaceIdUtility();
    getResourceList();
    getUserInfoInitial();
    getAllExamSpacesList();
  }

  //Get all exam spaces List
  const getAllExamSpacesList = async () => {
    let examSpaceResponse = await getAllExamSpaces();
    if (examSpaceResponse.status) {
      setExamSpacesList(examSpaceResponse.data)
      if (examSpaceResponse.data.length) {
        for (let i of examSpaceResponse.data) {
          if (i.is_default) {
            setSelectedExamSpace(i)
          }
        }
      }
    }
  }


  async function checkFreeUserUploadLimit() {
    try {
      let user_info_response = undefined;
      user_info_response = await GetUserInfoAPI();
      if (user_info_response !== undefined) {
        if (user_info_response.data && user_info_response.data.length >= 0) {
          if (user_info_response.data[0].plan_type === "free") {
            if (user_info_response.data[0].resource_count >= 1) {
              toast.error(
                <div dangerouslySetInnerHTML={{ __html: upload_file_error_text }} />
                , {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: true,
                });
              fileInputElement.current.value = "";
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        } else {
          toast.error("user information not found", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: true,
          });
          fileInputElement.current.value = "";
          return false;
        }
      }
    } catch (err) {
      console.error('documents', err)
    }
  }


  const handleFileUpload = async (e) => {
    // only one file can be uploaded at a time
    if (selectedFilesArray.current.length <= 0) {
      // free user check file upload limit
      // let fileUploadLimit = await checkFreeUserUploadLimit();
        // check file type PDF only allowed
        // also add support for ".odt", ".rtf", ".md", ".txt", ".doc", ".docx", ".epub", ".ppt", ".pptx", ".odp"
        if (fileInputElement.current !== undefined && allowedFileTypes.includes(fileInputElement.current.files[0].type)) {
          // check file size less than 40MB is allowed
          if (Math.round(fileInputElement.current.files[0].size / (1000 * 1000)) < 40) {
            // count of no files in uploading state
            selectedFilesArray.current.push(fileInputElement.current.files[0])
            setUserSelectedFile(fileInputElement.current.files[0]);
            // call upload file api
            var token = localStorage.getItem('token');           
            let formData1 = new FormData();
            formData1.append('file', fileInputElement.current.files[0])
            formData1.append('default_examspace_id', global_default_examspace_id.current)
            let api_response = await axios.post(`${apiUrl}/examspace/resource`, formData1, {
              headers: {
                'Content-Type': 'form-data',
                "Authorization": "Bearer " + token,
              },
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                if (innerProgressBar && innerProgressBar.current && percentCompleted > 50) {
                  innerProgressBar.current.style.transition = "width 5s"
                } else {
                  if (innerProgressBar && innerProgressBar.current){
                    innerProgressBar.current.style.transition = "width 1s"
                  }
                }
                setProgress(percentCompleted);
              },
            });
            let response = api_response.data;
            uploadedFileInfo.current = response.data[0];
            if (response.status) {
              getAllExamSpacesList()
              getResourceList();
              if (response.message.toLowerCase() === "file upload success") {
                if (response.data[0]["is_first_document_uploaded"] === false) {
                  handleMarketingData();
                }
                toast.success("File uploaded successfully", {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: true,
                });
                resetValues();
              } else {
                toast.error(response.message, {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: true,
                });
                // deleteErrorFile();
                resetValues();
              }
            } else if(response.status === false){
              toast.dismiss();
              if(response.message === "In free plan you can't upload more than 1 file at a time"){
                toast.error(
                  <div dangerouslySetInnerHTML={{ __html: upload_file_error_text }} />
                  ,
                  {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: true,
                  }
                );
              } else if(response.message === "In free plan you can't upload more than 3 files")  {
                toast.error(
                  <div dangerouslySetInnerHTML={{ __html: delete_file_error_text }} />
                  ,
                  {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: true,
                  }
                );
              } else {
                toast.error(response.message, {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: true,
                });
              }
              resetValues();
            }
          } else {
            toast.error("Oops, your file is too large. Please upload max 40MB files", {
              position: toast.POSITION.TOP_CENTER,
            });
            resetValues();
          }
        } else {
          toast.error("The supported file formats are: .pdf, .docx, .txt, .odt, .rtf, .md, .doc, .ppt, .pptx and .odp", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: true,
          });
          resetValues();
        }
    } else {
      toast.warning("Please wait while file uploading!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
    }
  };


  function handleMarketingData() {
    try {
      // google tag manager
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "First_File_Upload",
        user_id: String(localStorage.getItem("userId")) || "",
      });
    } catch (error) {
      console.error('document page', error);
    }
  }

  async function getUserInfoInitial() {
    try {
      let user_info_res = undefined;
      user_info_res = await GetUserInfoAPI();
      if (user_info_res !== undefined) {
        if (user_info_res.data && user_info_res.data.length > 0) {
          setUserPlanType(user_info_res.data[0].plan_type)
        }
      }
    } catch (err) {
      console.error('documents', err)
    }
  }


  async function getResourceList() {
    let response = await listAllUnskippedResources(global_default_examspace_id.current);
    if (response.status) {
      setShowAllFiles(response.data);
    } else {
      toast.error(response.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
    }
  }


  function resetValues() {
    if (fileInputElement && fileInputElement.current){
      fileInputElement.current.value = "";
    }
    setUserSelectedFile(null);
    setProgress(0);
    uploadedFileInfo.current = null;
    selectedFilesArray.current = [];
  }


  async function checkDeleteLimit() {
    try {
      let user_info_response = undefined;
      user_info_response = await GetUserInfoAPI();
      if (user_info_response !== undefined) {
        if (user_info_response.data && user_info_response.data.length > 0) {

          if (user_info_response.data.length >= 0) {
            if (user_info_response.data[0].plan_type === "free") {
              if (user_info_response.data[0].documents_uploaded_count > 2) {
                toast.error(<div dangerouslySetInnerHTML={{ __html: delete_file_error_text }} />, {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: true,
                });
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          } else {
            toast.error("user information not found", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: true,
            });
            return false;
          }
        }
      }
    } catch (err) {
      console.error('documents', err)
    }
  }


  const handleClosePopUp = (props) => {
    setShowExamSpace(false)
  }


  const handleRemoveResoure = async (ex_id, re_id) => {

    let getResponse = await DeleteResource(ex_id, re_id)
    if (getResponse) {
      if (getResponse.status) {
        getAllExamSpacesList();
      } else {
        if(getResponse.message === "In free plan you can't upload more than 3 files")  {
          toast.error(
            <div dangerouslySetInnerHTML={{ __html: delete_file_error_text }} />
            ,
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: true,
            }
          );
        } else {
          toast.error(getResponse.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }        
      }
    } 
  }

  async function getDefaultExamspaceIdUtility(){
    return new Promise( async(resolve, reject) => {
      let default_examspace_id = "";
      if( Object.keys(sharedUserInfo).length > 0){
        default_examspace_id = sharedUserInfo.default_examspace_id;
        global_default_examspace_id.current = sharedUserInfo.default_examspace_id;
        resolve(default_examspace_id);
      } 
      else {
        let getResponse = await GetUserInfoAPI()
        if (getResponse.status) {
          if(getResponse.data.length > 0){
            if(Object.keys(getResponse.data[0]).length > 0){
              setSharedUserInfo(getResponse.data[0]);
              default_examspace_id = getResponse.data[0]["default_examspace_id"];
              global_default_examspace_id.current = getResponse.data[0]["default_examspace_id"];
              resolve(default_examspace_id);
            }
          }
        }
      }
      
    });
  }  

  function checkExamspaceNameLength(name){
    if(name.length <= 25){
        return name;
    } else {
        return `${name.substring(0, 25)}...`;
    }
}

  return (
    <>
      {/* <Sidebar/> */}
      <main className={style["container"]}>
        <div className={style["content"]}>
          <div className={style["title"]}>
            <h1>Documents</h1>
          </div>
          <div className={style["exam-space-menu"]}>
            <p style={{ opacity: showAddFileOption ? '0.3' : '1' }} className={style["exam-space-text"]}>Examspace:</p>
            {ExamSpacesList.length ?
              <>
                {ExamSpacesList.map((file) => (
                  <>
                    {
                      file.is_default ?
                        <div style={{ opacity: showAddFileOption ? '0.3' : '1' }} onClick={() => setShowExamSpace('examSpace')} className={style["exam-space-topic"]}>
                          <p>{checkExamspaceNameLength(file.title)}</p>
                          <CiMaximize1 className={style["max-icon"]} />
                        </div>
                        :
                        null
                    }
                  </>
                ))}
              </>
              :
              null
            }

            {/* document_upload Desktop */}
            <div id="document_upload" onClick={() => { fileInputElement.current.click() }} className={style["exam-space-add-new-file"]}>
              <AiOutlinePlus className={style["add-icon"]} />
              <p>Add new file</p>
              <input type="file"
                id="document-file-upload-file-input-1"
                hidden accept=".pdf, .docx, .txt, .odt, .rtf, .md, .doc, .ppt, .pptx, .odp"
                ref={fileInputElement}
                onChange={(e) => { handleFileUpload(e) }} />
            </div>

            {/* document_upload Mobile */}
            <div className={style["exam-space-add-file-container"]}>
              <div onClick={() => {
                if (window.innerWidth < 800) {
                  setShowAddFileOption(!showAddFileOption)
                }
              }
              }
                style={{ opacity: showAddFileOption ? '0.3' : '1' }} id="document_upload" className={style["exam-space-add-file"]}>
                <AiOutlinePlus className={style["add-icon"]} />
                <p className={style["exam-space-add-file-mobile"]}>Add file</p>
                <input type="file"
                  id="document-file-upload-file-input-1"
                  hidden accept=".pdf, .docx, .txt, .odt, .rtf, .md, .doc, .ppt, .pptx, .odp"
                  ref={fileInputElement}
                  onChange={(e) => { handleFileUpload(e) }} />
              </div>

              {showAddFileOption ?
                <div className={style["exam-space-add-file-drop-menu"]}>
                  <div onClick={() => { fileInputElement.current.click() }} className={style["new-file-drop-menu"]}>
                    <VscNewFile className={style["add-drop-icon"]} />
                    <p>New file</p>
                  </div>
                  <div onClick={() => { setShowExamSpace('import'); setShowAddFileOption(!showAddFileOption) }} className={style["upload-file-drop-menu"]}>
                    <MdOutlineDriveFolderUpload className={style["add-drop-icon"]} />
                    <p
                    // style={{ textWrap: 'nowrap' }}
                    >From examspace</p>
                  </div>
                </div>
                :
                null
              }
            </div>


            {/* Display only desktop */}
            <div
              style={ExamSpacesList.length > 1 && window_width > 800 ? {display: "flex"} : {display: "none"}}
              onClick={() => setShowExamSpace('import')} className={style["exam-space-import"]}>
              <BiImport className={style["import-icon"]} />
              <p>Import existing</p>
            </div>
          </div>



          <div className={style["column-one"]}>
            {/* <div id="document_upload" onClick={() => { fileInputElement.current.click() }} className={style["upload-box"]}>
                <img src={plusIcon} alt="plus-icon" />
                <p>Add new file</p>
                <input type="file"
                  id="document-file-upload-file-input-1"
                  hidden accept=".pdf, .docx, .txt"
                  ref={fileInputElement}
                  onChange={(e) => { handleFileUpload(e) }} />
                  
              </div> */}



          </div>
          <div style={{ opacity: showAddFileOption ? '0.3' : '1' }} className={style["two-column-layout"]}>
            <div className={style["column-two"]}>
              {/* <h3>Your files</h3> */}
              {
                userSelectedFile
                  ?
                  <>
                    <div className={style["upload-file-container"]}>
                      <div className={style["upload-file-content"]}>
                        <div className={style["upload-file-loading"]}>
                          <img src={LoadingGif} alt="loading gif" />
                          <p>loading</p>
                        </div>
                        <h2 className={style["upload-file-title"]}>
                          {userSelectedFile.name}
                        </h2>
                      </div>
                      <dir className={style["progress-bar"]}>
                        <div className={style["progress-bar-white"]}></div>
                        <div ref={innerProgressBar} className={style["progress-bar-inner"]}
                          style={{ width: `${progress}%` }}
                        >
                          {" "}
                        </div>
                      </dir>
                    </div>
                  </>
                  :
                  <>
                  </>
              }
              <section style={userPlanType === "free" ? { height: "auto" } : { height: "300px" }} className={style["docs-list-box"]}>
                {
                  ExamSpacesList.length > 0 ?
                    ExamSpacesList.map((file) => {
                      return (
                        <>
                          {file.is_default ?
                            <>
                              {file.resources.map((resource) => (
                                <div className={style["docs-item"]}>
                                  <p>{resource.resource_display_name.length > 40 ? resource.resource_display_name.slice(0, 40) + '...' : resource.resource_display_name}</p>
                                  <TrashIcon onClick={() => handleRemoveResoure(file.id, resource.id)} className={style["trash-icon"]} />

                                  {/* <img
                              src={dustbinIcon} alt="delete-icon"
                              onClick={() => handledeleteFile(file.resource_id)}
                              style={file.resource_id === "" ? { pointerEvents: "none" } : { pointerEvents: "auto" }}
                            /> */}
                                </div>
                              ))

                              }
                            </>
                            :
                            null
                          }
                        </>
                      )
                    })
                    : null
                }
              </section>
              {/* <section className={style["docs-list-box"]}>
                {
                  showallfiles.length > 0 ?
                    showallfiles.map((file) => {
                      return (
                        <>
                          <div className={style["docs-item"]}>
                            <p>{file.resource_title.length > 40 ? file.resource_title.slice(0, 40) + '...' : file.resource_title}</p>
                            <TrashIcon className={style["trash-icon"]} />

                             <img
                              src={dustbinIcon} alt="delete-icon"
                              onClick={() => handledeleteFile(file.resource_id)}
                              style={file.resource_id === "" ? { pointerEvents: "none" } : { pointerEvents: "auto" }}
                            /> 
                          </div>
                        </>
                      )
                    })
                    : null
                }
              </section> */}
              <div style={userPlanType === "free" ? { display: "block" } : { display: "none" }}>
                <article
                  className={`${style["file-limit-info"]} ${style["file-limit-info-desktop"]}`}>
                  <h4>Free mode limit:</h4>
                  <div>
                    <img src={blackDot} alt="dot-icon" />
                    <p>1 document at a time</p>
                  </div>
                  <div style={{ marginBottom: "15px" }}>
                    <img src={blackDot} alt="dot-icon" />
                    <p>Upload up to 3 documents in total</p>
                  </div>
                  <Link id="document_go_to_push_mode" to={"/pushmode"}>Go Unlimited</Link>
                </article>
              </div>

              <div style={userPlanType === "free" ? { display: "block" } : { display: "none" }}>
                <article
                  className={`${style["file-limit-info"]} ${style["file-limit-info-mobile"]}`}>
                  <h4>Free mode limit:</h4>
                  <div>
                    <img src={blackDot} alt="dot-icon" />
                    <p>1 document at a time</p>
                  </div>
                  <div style={{ marginBottom: "15px" }}>
                    <img src={blackDot} alt="dot-icon" />
                    <p>Upload up to 3 documents in total</p>
                  </div>
                  <Link to={"/pushmode"}>Go Unlimited</Link>
                </article>
              </div>
            </div>
          </div>

          <p className={style["copyright-text"]}>
            Make sure to upload only copyrighted free documents. We are using a plagiarism detector to protect IP ownership.
          </p>
        </div>
      </main >
      <ToastContainer />

      <ExamSpacePopup data={ExamSpacesList} popup={showExamspace} close={handleClosePopUp} reCallGet={getAllExamSpacesList} />


      {/* Exam Space PopUp */}
      {/* {showExamspace === 'import' ?
        <ExamSpacePopup>
          <main

            className={styleExamSpace["exam-space-popup-container"]}
            style={{ display: "block" }}
          >
            <div className={styleExamSpace["exam-space-popup-backdrop"]}>
              <div className={styleExamSpace["exam-space-popup-content"]}>
                <div className={styleExamSpace['x-mark-icon-parent']}>
                  <XMarkIcon onClick={() => setShowExamSpace(false)} className={styleExamSpace['x-mark-icon']} />
                </div>
                <div className={styleExamSpace["exam-space-popup-section"]}>
                  <div className={styleExamSpace["exam-space-heading"]}>
                    <RiArrowDropDownLine className={style["arrow-down-icon"]} />
                    <h4 className={styleExamSpace["exam-space-popup-title"]}>Biology</h4>
                  </div>
                  <p>Organize your documents for each exam</p>
                </div>
              </div>
            </div>
          </main>
        </ExamSpacePopup>
        :
        null
      } */}
    </>
  );
};

export default Uploadfile;