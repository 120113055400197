import "./HomeNew.scss";
import React, { useRef, useEffect, useState, Suspense, useContext } from "react";
import {
    getUserAuthToken,
    getApiUrl,
    listAllUnskippedResources,
    getExamSpaceDashboard,
    ExamSpaceUploadResource,
    getAllReviewQuestions,
    getAllConcepts,
    GetUserInfoAPI,
    CreateGtagEvent,
    getUserAnalyticsAPI,
    getAllExamSpaces
} from "../../helpers/requests";
import WaveSVG from "../../Components/WaveSVG/WaveSVG";
import lockIcon from "../../assets/lock.png";
import loadingsmall from "../../assets/loading-svg-orange.svg";
import weatherImg from "../../assets/cloudy.png";
import { useNavigate } from "react-router-dom";
import PaymentPopup from "../../Components/PaymentPopup/PaymentPopup";
import closeIcon from "../../assets/close.svg";
import uploadIcon from "../../assets/upload-white-icon.png";
import clearSkyIcon from "../../assets/weather/clear_sky.png";
import cloudsIcon from "../../assets/weather/clouds.png";
import mistIcon from "../../assets/weather/mist.png";
import rainIcon from "../../assets/weather/rain.png";
import snowIcon from "../../assets/weather/snow.png";
import thunderstormIcon from "../../assets/weather/thunderstorm.png";
import LoaderSpinner from "../../Components/LoaderSpinner/LoaderSpinner";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Grid } from "swiper/modules";
import { ToastContainer, toast } from "react-toastify";
import { install } from "ga-gtag";
import style from "./home.module.css";
// import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import Sidebar from "../../Components/Sidebar/Sidebar";
import newLockIcon from "../../assets/orange_lock.png";
import { CiMaximize1 } from "react-icons/ci";
import { RiArrowDropDownLine } from "react-icons/ri";
import ExamSpacePopup from "../../Components/ExamSpacePopup/ExamSpacePopup";
import GaugeChart from 'react-gauge-chart';
import orange_lock from "../../assets/orange_lock.png";
import { CurrentUserInfo } from "../../Components/ContextStore/ContextStore";
import FileUploadComponent from '../../Components/FileUploadComponent/FileUploadComponent';


const DonutChart = React.lazy(() => import('../../Components/DonutChart/DonutChart'));
const menuName = [
    {
        name: "correct",
        color: "#C4D7B2",
        id: 1,
    },
    {
        name: "wrong",
        color: "#FFB84C",
        id: 2,
    },
    {
        color: "#F0F5FF",
        name: "new",
        id: 3,
    },
]

const weatherIconMap = {
    clear_sky: clearSkyIcon,
    clouds: cloudsIcon,
    mist: mistIcon,
    rain: rainIcon,
    snow: snowIcon,
    thunderstorm: thunderstormIcon
}


export default function HomeNew() {
const { sharedUserInfo, setSharedUserInfo } = useContext(CurrentUserInfo);
const global_default_examspace_id = useRef("");
const checkTopicAvailableIntervalId = useRef([]);
const navigate = useNavigate();
var token = getUserAuthToken();
var apiUrl = getApiUrl();
const [loaderStatus, setLoaderStatus] = useState(false);
const [weatherIconData, setWeatherIconData] = useState(clearSkyIcon);
const [resource_available, set_resource_available] = useState(true);
const [topic_available, set_topic_available] = useState(true);
const [review_question_available, set_review_question_available] = useState(true);
const [topic_list_array, Set_topic_list_array] = useState([]);
const [question_list_array, Set_question_list_array] = useState([]);
const [usertype, setUserType] = useState("");
const [showloading, setShowLoading] = useState("");
const [slide_count, set_slide_count] = useState(3);
const [window_width, set_window_width] = useState(window.innerWidth);
const [show_review_question_popup, Set_show_review_question_popup] = useState(
    {
        open: false,
        question: "",
        questionAns: "",
    }
);
const [feedback_popup_options, set_feedback_popup_options] = useState({
    open: false,
    type: `answer`,
    question_id: "",
});
const fileUploadRef = useRef(null);

const [showExamspace, setShowExamSpace] = useState(false)
const [showExamspaceDropMenu, setShowExamSpaceDropMenu] = useState(false)
const [ExamSpacesList, setExamSpacesList] = useState([])
const [selectedExamSpace, setSelectedExamSpace] = useState({})
const [currentUserInfo, setCurrentUserInfo] = useState([])
const [dashBoardList, setDashBoardList] = useState([])


useEffect(() => {
    if ( apiUrl === "https://app.dende.ai/api" || apiUrl === "https://staging.dende.ai/api") {
        install("G-K9ES37HT6F", { debugger: true, send_page_view: false });
    } else {
        install("G-GMWDDVX7G6", { debugger: true, send_page_view: false });
    }
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("paymentstatus") !== null && urlParams.get("paymentstatus") !== undefined) {
        if (urlParams.get("paymentstatus") === "true") {
            set_feedback_popup_options({
                open: true,
                type: `answer`,
                question_id: "",
            });
            // getUserInfoAPI();
            handleMarketingData();
        }
    }
}, []);


useEffect(() => {
    proxyCallGetDefaultExamspaceIdUtility();
    
    const handleResize = () => {
        set_window_width(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    
    return () => {
        window.removeEventListener("resize", handleResize);
        checkTopicAvailableIntervalId.current.forEach((intervalId)=>{
            clearInterval(intervalId);
        })
    };
}, [sharedUserInfo]);

// useEffect(() => {
//     if (resource_available && topic_available) {
//         getDashBoardData()
//     }
// }, [resource_available, topic_available, sharedUserInfo]);


// useEffect(() => {
//     var getUserType = localStorage.getItem("plan_type");
//     if (getUserType) {
//         setUserType(getUserType);
//     }
// }, [question_list_array]);


async function proxyCallGetDefaultExamspaceIdUtility() {
    let examspace_id = await getDefaultExamspaceIdUtility();
    checkForEmptyData();
    // setGetUserInfo([sharedUserInfo]);
    setCurrentUserInfo([sharedUserInfo]);
    // getAllExamSpacesList()
}

async function checkForEmptyData() {
    try {
        let examspace_api_response = await getAllExamSpaces();
        if (examspace_api_response.data && examspace_api_response.data.length > 0) {
            // get default examspace
            let selectedExamspaceData = examspace_api_response.data.find(item => item.id === global_default_examspace_id.current);
            setExamspaceData(examspace_api_response)
            if (selectedExamspaceData["resources"].length > 0) {
                set_resource_available(true);
                // get dashbord data
                let dashboard_api_response = await getExamSpaceDashboard(global_default_examspace_id.current);
                if(dashboard_api_response.data && dashboard_api_response.data.length > 0 && dashboard_api_response.data[0]["topic_name"] !== ""){
                    set_topic_available(true);
                    setDashBoardList(dashboard_api_response.data);
                    setWeatherIconData(weatherIconMap[dashboard_api_response.data[0]["weather_data"]]);
                    // get review question
                    let review_question_api_resposne = await getAllReviewQuestions(global_default_examspace_id.current);
                    if (review_question_api_resposne.data.length > 0) {
                        set_review_question_available(true);
                        Set_question_list_array(review_question_api_resposne.data);
                    } else {
                        set_review_question_available(false);
                    }
                } else {
                    set_topic_available(false);
                    pollTopicList();
                }
            } else {
                set_resource_available(false);
            }
        } else {
            toast.error(examspace_api_response.message, {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    } catch (err) {
        console.error('home', err)
    }
}


async function pollTopicList() {
    let timeDuration = 0;
    let tempoaryIntervalId = setInterval(async () => {
        timeDuration += 10;
        if (timeDuration >= 600) {
            checkTopicAvailableIntervalId.current.forEach((intervalId)=>{
                clearInterval(intervalId);
            })
            return false;
            
        }
        let dashboard_api_response = await getExamSpaceDashboard(global_default_examspace_id.current)
        if (dashboard_api_response.data && dashboard_api_response.data.length > 0 && dashboard_api_response.data[0]["topic_name"] !== "") {
            checkTopicAvailableIntervalId.current.forEach((intervalId)=>{
                clearInterval(intervalId);
            })
            set_topic_available(true);
            setDashBoardList(dashboard_api_response.data);
            setWeatherIconData(weatherIconMap[dashboard_api_response.data[0]["weather_data"]])
            // get review question
            let review_question_api_resposne = await getAllReviewQuestions(global_default_examspace_id.current);
            if (review_question_api_resposne.data.length > 0) {
                set_review_question_available(true);
                Set_question_list_array(review_question_api_resposne.data);
            } else {
                set_review_question_available(false);
            }
        } else {
            set_topic_available(false);
        }
    }, 10000);
    checkTopicAvailableIntervalId.current.push(tempoaryIntervalId);
}


// function initialCallGetUserInfo() {
//     let get_user_info = localStorage.getItem('user_info')
//     if (get_user_info) {
//         setGetUserInfo(JSON.parse(get_user_info))
//     }
// }


const getAllExamSpacesList = async () => {
    let examSpaceResponse = await getAllExamSpaces();
    if (examSpaceResponse.status) {
        setExamSpacesList(examSpaceResponse.data)
        if (examSpaceResponse.data.length) {
            for (let i of examSpaceResponse.data) {
                if (i.is_default) {
                    setSelectedExamSpace(i)
                }
            }
        }
    }
}

function setExamspaceData(examspace_api_response){
    setExamSpacesList(examspace_api_response.data)
    if (examspace_api_response.data.length) {
        const defaultExamSpace = examspace_api_response.data.find(examSpace => examSpace.is_default);
        if (defaultExamSpace) {
            setSelectedExamSpace(defaultExamSpace);
        }
    }
}

async function handleMarketingData() {
    try {
        let userAnalyticsResponse = await getUserAnalyticsAPI();
        let userInfoResponse = await GetUserInfoAPI();
        let temp_user_id = String(localStorage.getItem("userId")) || "";
        let temp_plan_name = userInfoResponse.data?.[0]?.plan_type ?? "";
        let temp_plan_price = temp_plan_name === "30_days" ? "6.9" : temp_plan_name === "3_months" ? "17.40" : "";
        let temp_transaction_id = userInfoResponse.data?.[0]?.subscription_id ?? "";
        
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
            event: "purchase",
            ecommerce: {
                transaction_id: temp_transaction_id,
                value: temp_plan_price,
                currency: "EUR",
                user_id: temp_user_id,
                first_name: userAnalyticsResponse?.data?.[0]?.first_name ?? "",
                surname: userAnalyticsResponse?.data?.[0]?.surname ?? "",
                email: userAnalyticsResponse?.data?.[0]?.email ?? "",
                phone: userAnalyticsResponse?.data?.[0]?.phone ?? "",
                street: userAnalyticsResponse?.data?.[0]?.street ?? "",
                city: userAnalyticsResponse?.data?.[0]?.city ?? "",
                country: userAnalyticsResponse?.data?.[0]?.country ?? "",
                postcode: userAnalyticsResponse?.data?.[0]?.postcode ?? "",
                items: [
                    {
                        item_name: temp_plan_name,
                        item_id: temp_transaction_id,
                        price: temp_plan_price,
                        quantity: 1,
                    },
                ]
            }
        });
    } catch (error) {
        console.error('pushmode page', error)
    }
}
    
    
    async function getUserInfoAPI() {
        let response_var = await GetUserInfoAPI();
        if (response_var.data.length >= 0) {
            localStorage.setItem("plan_type", response_var.data[0].plan_type);
            localStorage.setItem("plan_end_date", response_var.data[0].plan_end_date);
        }
    }
    

    function handleOpenReview(questionid, questionname) {
        setShowLoading(questionid);
        // setLoaderStatus(true);
        const body = {
            question_id: questionid,
        };
        
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(body),
        };
        fetch(`${apiUrl}/review-content`, requestOptions)
        .then((response) => response.json())
        .then((response) => {
            
            setShowLoading("");
            if (response.data.length > 0) {
                Set_show_review_question_popup({
                    open: true,
                    question: questionname,
                    questionAns: response.data[0].content,
                });
            } else {
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    }
    

    function closeReviewQuestionPopup(target) {
        if (target.className === "topic-review-question-popup-container") {
            Set_show_review_question_popup(false);
        }
    }
    
    
    function handleUploadSuccess(response){
        checkForEmptyData();
    }
    
    function triggerFileUpload() {
        fileUploadRef.current.triggerFileInput();
    }
    
    // Function to get the day suffix (st, nd, rd, th)
    function getDaySuffix(day) {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    }
    
    // Options for toLocaleDateString
    const options = {
        weekday: 'long',    // Monday
        day: 'numeric',     // 14
        month: 'short'      // Feb
    };
    
    
    function handleTodayDate() {
        const today = new Date();
        const formattedDate = today.toLocaleDateString('en-US', options);
        
        // Get the day and add the suffix
        const day = today.getDate();
        const dayWithSuffix = `${day}${getDaySuffix(day)}`;
        
        // Replace the day with the day and suffix
        const finalDate = formattedDate.replace(day, dayWithSuffix);
        return finalDate;
    }
    
    const handleClosePopUp = (props) => {
        setShowExamSpace(false)
    }
    
    const handleClickContinue = (id) => {
        navigate(`/quiz?topic_id=${id}`)
    }
    

    async function getDefaultExamspaceIdUtility() {
        return new Promise(async (resolve, reject) => {
            let default_examspace_id = "";
            if (Object.keys(sharedUserInfo).length > 0) {
                default_examspace_id = sharedUserInfo.default_examspace_id;
                global_default_examspace_id.current = sharedUserInfo.default_examspace_id;
                resolve(default_examspace_id);
            }
            else {
                let getResponse = await GetUserInfoAPI()
                if (getResponse.status) {
                    if (getResponse.data.length > 0) {
                        if (Object.keys(getResponse.data[0]).length > 0) {
                            setSharedUserInfo(getResponse.data[0]);
                            default_examspace_id = getResponse.data[0]["default_examspace_id"];
                            global_default_examspace_id.current = getResponse.data[0]["default_examspace_id"];
                            resolve(default_examspace_id);
                        }
                    }
                } else {
                    toast.error(getResponse.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            }
        });
    }
  
    function checkExamspaceNameLength(name){
        if(name.length <= 25){
            return name;
        } else {
            return `${name.substring(0, 25)}...`;
        }
	}
  
    function renderExamspaceUIButton(){
        return(
            <div className={style["exam-space-list"]}>
                {
                    Object.keys(selectedExamSpace).length
                    ?
                        <div
                            onClick={() => setShowExamSpace('examSpace')}
                            className={style["exam-space-topic"]}>
                            <p>{checkExamspaceNameLength(selectedExamSpace.title)}</p>
                            <CiMaximize1 className={style["max-icon"]} />
                            <RiArrowDropDownLine className={style["max-icon-mobile"]} />
                        </div>
                    :
                        null
                }
            </div>
        );
    }
    
    
    return (
        <>
        {/* <Sidebar /> */}
        <main className="topic-container">
        <div className="topic-wrapper">
        <div>
        <div className={`${style["folder-list"]}`}>
                {currentUserInfo.length ?
                    <div>
                        <p className={style["user-message"]}>Hi {currentUserInfo[0].name}, how do you feel today? </p>
                        <p className={style["user-message-mobile"]}>Hi {currentUserInfo[0].name} </p>
                        <div className={style["weather-container"]}>
                        {
                            weatherIconData
                            ?
                                <img src={weatherIconData} alt="weathe-icon" className={style["weather-image"]} />
                            :
                                <img src={cloudsIcon} alt="weathe-icon" className={style["weather-image"]} />
                        }
                        <p className={style["weather-message"]}>{handleTodayDate()}</p>
                        </div>
                    </div>
                    :
                    null
                }
                {renderExamspaceUIButton()}
            </div>
        </div>
            
            {resource_available ? (
                topic_available ? (
                    <>
                    <div className={style['folder-list-menu-display']}>
                    <div>
                    {dashBoardList[0] ?
                        <div className={style["content-card"]}>
                        <p className={style["content-card-title"]}>
                        {/* {dashBoardList[0].topic_name} */}
                        {dashBoardList[0].topic_name && dashBoardList[0].topic_name.length > 30
                            ? dashBoardList[0].topic_name.substring(
                                0,
                                30
                            ) + "..."
                            : dashBoardList[0].topic_name}
                            </p>
                            <p className={style["content-card-pages"]}>
                            {dashBoardList[0].topic_pages}
                            
                            {dashBoardList[0].topic_pages.length ?
                                <>
                                &nbsp;|&nbsp;
                                </>
                                :
                                null
                            }
                            
                            {dashBoardList[0].resource_name && dashBoardList[0].resource_name.length > 12 ?
                                dashBoardList[0].topic_name.substring(
                                    0,
                                    12
                                ) + "..." : dashBoardList[0].resource_name
                            }
                            </p>
                            <div
                            style={
                                dashBoardList[0].topic_score === 0
                                ? { width: "10%" }
                                : { width: dashBoardList[0].topic_score + "%" }
                            }
                            className={`${style["content-progress-bar"]}`}
                            ></div>
                            <hr className={style["content-card-continue-hr-line"]} />
                            <p onClick={() => handleClickContinue(dashBoardList[0].topic_id)} className={style["content-card-continue"]}>
                            Continue
                            </p>
                            </div>
                            :
                            null
                        }
                        </div>
                        
                        
                        <div>
                        {dashBoardList[0] ?
                            <>
                            <div className={style["content-card-chart"]}>
                            <div className={style["chart-menu-section"]}>
                            {menuName.map((menu) => (
                                <div className={style["chart-menu-heading"]}>
                                <div
                                style={{ backgroundColor: menu.color }}
                                className={style["chart-menu-color"]}>
                                {/* {menu.name}  */}
                                </div>
                                <p
                                className={style["chart-menu-text"]}>
                                {menu.name}
                                </p>
                                </div>
                            ))}
                            </div>

                            
                                <Suspense fallback={<div>Loading...</div>}>
                                <DonutChart data={dashBoardList} />
                                </Suspense>
                                <hr className={style["content-card-continue-hr-line"]} />
                                {
                                    currentUserInfo.length > 0 && currentUserInfo[0]["plan_type"] !== "free" 
                                        ?
                                            <p onClick={() => navigate('/concept')} className={style["chart-menu-questions"]}>
                                                See questions
                                            </p>
                                        :
                                            <div className={style['chart-menu-free-user']}>
                                                <img onClick={() => navigate('/pushmode')} src={orange_lock} alt="lock button" />
                                                <p onClick={() => navigate('/pushmode')} className={style["chart-menu-questions"]}>
                                                Unlock all questions
                                                </p>
                                            </div>
                                }
                                </div>
                                </>
                                :
                                null
                            }
                            </div>
                            </div>
                            </>
                        ) : (
                            <>
                            {/* Topic generating UI  */}
                            <div className="home-new-topic-gen-container">
                            <img src={loadingsmall} alt="loading icon" />
                            <p>We are extracting the main concepts from your file</p>
                            </div>
                            </>
                        )
                    ) : (
                        <>
                        {/* file upload UI */}
                        <div className="home-new-file-upload-container">
                        <p>See the concepts you should master before the exam</p>
                        <FileUploadComponent
                        ref={fileUploadRef}
                        onUploadSuccess={handleUploadSuccess}
                        examspaceId={global_default_examspace_id.current}
                        />
                        <button id="home_concepts_file_upload" onClick={triggerFileUpload}>
                        <img src={uploadIcon} alt="upload icon" />
                        Upload a file
                        </button>
                        </div>
                        </>
                    )}
                    
                    <section className="topic-review-section">
                    <div className="topic-review-title">
                    <h2>Explanations</h2>
                    </div>
                    
                    {resource_available ? (
                        review_question_available ? (
                            // Normal UI
                            <>
                            {currentUserInfo.length > 0  && currentUserInfo[0]["plan_type"] === "free" ? (
                                <div className="topic-review-questions-list">
                                {question_list_array.length ? (
                                    <>
                                    {question_list_array
                                        .slice(0, 10)
                                        .map((item, index) => {
                                            return (
                                                <>
                                                {index == 0 || index == 1 ? (
                                                    <div className="topic-review-question">
                                                    {/* <p>
                                                        {item["question_name"]}
                                                        <span className="PushMode">Read</span>
                                                        </p> */}
                                                        <div className="first-element">
                                                        <p>{item["question_name"]}</p>
                                                        </div>
                                                        {showloading == item.question_id ? (
                                                            <img
                                                            width={"auto"}
                                                            height={"35px"}
                                                            style={{ marginLeft: "auto" }}
                                                            className="topic-review-loading-gif"
                                                            src={loadingsmall}
                                                            alt="loading..."
                                                            />
                                                        ) : (
                                                            <div className="topic-review-question-right">
                                                            <span
                                                            onClick={() => {
                                                                handleOpenReview(
                                                                    item.question_id,
                                                                    item["question_name"]
                                                                );
                                                            }}
                                                            className="PushMode"
                                                            >
                                                            Read
                                                            </span>
                                                            </div>
                                                        )}
                                                        </div>
                                                    ) : (
                                                        <div className="topic-review-question disable-question">
                                                        <div
                                                        onClick={() => {
                                                            navigate(`/pushmode`);
                                                        }}
                                                        >
                                                        <img
                                                        style={{ height: "35px", opacity: 1 }}
                                                        src={lockIcon}
                                                        alt="lock icon"
                                                        />
                                                        </div>
                                                        <div className="first-element">
                                                        <p>{item["question_name"]}</p>
                                                        </div>
                                                        </div>
                                                    )}
                                                    </>
                                                );
                                            })}
                                            </>
                                        ) : null}
                                        </div>
                                    ) : null}
                                    {currentUserInfo.length > 0  && currentUserInfo[0]["plan_type"] !== "free" ? (
                                        <div className="topic-review-questions-list">
                                        {question_list_array.length ? (
                                            <>
                                            {question_list_array
                                                .slice(0, 10)
                                                .map((item, index) => {
                                                    return (
                                                        <>
                                                        <div className="topic-review-question">
                                                        <div className="first-element">
                                                        <p>{item["question_name"]}</p>
                                                        </div>
                                                        {showloading === item.question_id ? (
                                                            <img
                                                            width={"auto"}
                                                            height={"35px"}
                                                            style={{ marginLeft: "auto" }}
                                                            src={loadingsmall}
                                                            alt="loading..."
                                                            />
                                                        ) : (
                                                            <div className="topic-review-question-right">
                                                            <span
                                                            onClick={() => {
                                                                handleOpenReview(
                                                                    item.question_id,
                                                                    item["question_name"]
                                                                );
                                                            }}
                                                            className="PushMode"
                                                            >
                                                            Read
                                                            </span>
                                                            </div>
                                                        )}
                                                        </div>
                                                        </>
                                                    );
                                                })}
                                                </>
                                            ) : null}
                                            </div>
                                        ) : null}
                                        </>
                                    ) : (
                                        // generating review question UI
                                        <>
                                        <div
                                        style={{ marginBottom: "100px" }}
                                        className="home-new-topic-gen-container"
                                        >
                                        <p style={{ marginTop: "0px" }}>
                                        Reply to some questions to see what you need to review
                                        </p>
                                        </div>
                                        </>
                                    )
                                ) : (
                                    // upload file UI
                                    <>
                                    <div className="home-new-file-upload-container">
                                    <p>Upload at least 1 document to see your stats</p>
                                    <FileUploadComponent
                                    ref={fileUploadRef}
                                    onUploadSuccess={handleUploadSuccess}
                                    examspaceId={global_default_examspace_id.current}
                                    />
                                    <button id="home_explanation_file_upload" onClick={triggerFileUpload}>
                                    <img src={uploadIcon} alt="upload icon" />
                                    Upload a file
                                    </button>
                                    </div>
                                    </>
                                )}
                                </section>
                                </div>
                                </main>
                                
                                
                                
                                
                                
                                <section
                                className="topic-review-question-popup-container"
                                style={
                                    show_review_question_popup.open
                                    ? { display: "block" }
                                    : { display: "none" }
                                }
                                onClick={(e) => {
                                    closeReviewQuestionPopup(e.target);
                                }}
                                >
                                <div className="topic-review-question-popup-content">
                                <img
                                src={closeIcon}
                                alt="close icon"
                                onClick={() => {
                                    Set_show_review_question_popup(false);
                                }}
                                />
                                <h6 className="topic-review-question-popup-title">
                                {show_review_question_popup.question}
                                </h6>
                                <p className="topic-review-question-popup-explanation">
                                {show_review_question_popup.questionAns}
                                </p>
                                </div>
                                </section>
                                
                                <ExamSpacePopup data={ExamSpacesList} popup={showExamspace} close={handleClosePopUp} reCallGet={getAllExamSpacesList} />
                                
                                <PaymentPopup
                                props={feedback_popup_options}
                                nextQuiz={false}
                                ></PaymentPopup>
                                <LoaderSpinner data={loaderStatus}></LoaderSpinner>
                                <ToastContainer />
                                </>
                            );
                        }
